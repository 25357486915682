import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import UserPrivateRoute from './components/common/UserPrivateRoute';
import AdminPrivateRoute from './components/admin/AdminPrivateRoute';
import ManagerPrivateRoute from './components/manager/ManagerPrivateRoute';

import Dashboard from './components/dashboard/Dashboard';
import UploadProfilePic from './components/dashboard/UploadProfilePic';
import ManagerDashboard from './components/manager/ManagerDashboard';
import AdminDashboard from './components/admin/AdminDashboard';
import UserDetails from './components/admin/UserDetails';

import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Landing from './components/layout/Landing';
import CoachHistory from './components/layout/CoachHistory';
import InMemory from './components/layout/InMemory';
import CommService from './components/layout/CommService';
import ArchivedPhotos from './components/layout/ArchivedPhotos';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import CreateNewPass from './components/auth/CreateNewPass';
import CreateProfile from './components/create-profile/CreateProfile';
import EditProfile from './components/edit-profile/EditProfile';
import AddExperience from './components/add-credentials/AddExperience';
import AddEducation from './components/add-credentials/AddEducation';
import Profiles from './components/profiles/Profiles';
import Profile from './components/profile/Profile';
import NotFound from './components/not-found/NotFound';
import Posts from './components/posts/Posts';
import Post from './components/post/Post';
import CalEvents from './components/calEvents/CalEvents';
import OneCalEvent from './components/calEvents/OneCalEvent';
import YouTubeIndex from './components/youtube/YouTubeIndex';


import './App.css';
import store from './store';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import { clearCurrentProfile } from './actions/profileActions';

// check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = '/login';
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            <Route exact path="/" component={Landing} />
            <Route exact path="/history" component={CoachHistory} />
            <Route exact path="/service" component={CommService} />
            <Route exact path="/memory" component={InMemory} />
            <Route exact path="/photos" component={ArchivedPhotos} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/create-new-pass/:token" component={CreateNewPass} />
            <Route exact path="/not-found" component={NotFound} />
            <Switch>
              <UserPrivateRoute
                exact
                path="/search-youtube"
                component={YouTubeIndex}
              />
            </Switch>
            <Switch>
                <ManagerPrivateRoute exact path="/manager" component={ManagerDashboard} />
              </Switch>
            <div className="container">
              <Route exact path="/profiles" component={Profiles} />
              <Switch>
                <UserPrivateRoute
                  exact
                  path="/profile/:handle"
                  component={Profile}
                />
              </Switch>
              <Switch>
                <UserPrivateRoute
                  exact
                  path="/profile/user/:id"
                  component={Profile}
                />
              </Switch>
              <Switch>
                <UserPrivateRoute exact path="/dashboard" component={Dashboard} />
              </Switch>
              <Switch>
                <UserPrivateRoute
                  exact
                  path="/create-profile"
                  component={CreateProfile}
                />
              </Switch>
              <Switch>
                <UserPrivateRoute
                  exact
                  path="/edit-profile"
                  component={EditProfile}
                />
              </Switch>
              <Switch>
                <UserPrivateRoute
                  exact
                  path="/add-experience"
                  component={AddExperience}
                />
              </Switch>
              <Switch>
                <UserPrivateRoute
                  exact
                  path="/add-education"
                  component={AddEducation}
                />
              </Switch>
              <Switch>
                <UserPrivateRoute
                  exact
                  path="/upload-profile-pic"
                  component={UploadProfilePic}
                />
              </Switch>
              <Switch>
                <UserPrivateRoute exact path="/feed" component={Posts} />
              </Switch>
              <Switch>
                <UserPrivateRoute exact path="/post/:id" component={Post} />
              </Switch>
              <Switch>
                <UserPrivateRoute exact path="/cal-events" component={CalEvents} />
              </Switch>
              <Switch>
                <UserPrivateRoute exact path="/cal-events/:id" component={OneCalEvent} />
              </Switch>
              <Switch>
                <AdminPrivateRoute exact path="/admin" component={AdminDashboard} />
              </Switch>
              <Switch>
                <AdminPrivateRoute exact path="/admin/user/:id" component={UserDetails} />
              </Switch>

              

            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
