import {
  ADD_PHOTO,
  GET_PHOTOS,
  GET_PHOTO,
  PHOTO_LOADING,
  DELETE_PHOTO
} from '../actions/types.js';

const initalState = {
  photos: [],
  photo: {},
  loading: false
};

export default function(state = initalState, action) {
  switch (action.type) {
    case PHOTO_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_PHOTOS: 
      return {
        ...state,
        photos: action.payload,
        loading: false
      };
    case GET_PHOTO:
      return {
        ...state,
        photo: action.payload,
        loading: false
      };
    case ADD_PHOTO: 
      return {
        ...state,
        photos: [action.payload, ...state.photos]
      };
    case DELETE_PHOTO: 
      return {
        ...state,
        photos: state.photos.filter(photo => photo._id !== action.payload)
      };
    default: 
      return state;
  }
}