import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CalEventItem from '../calEvents/CalEventItem';
import Spinner from '../common/Spinner';
import { getcalEvent } from '../../actions/calEventActions';

class OneCalEvent extends Component {
  componentDidMount() {
    this.props.getcalEvent(this.props.match.params.id);
  }

  render() {
    const { calEvent, loading } = this.props.calEvent;
    let calEventContent;

    if (calEvent === null || loading || Object.keys(calEvent).length === 0) {
      calEventContent = <Spinner />;
    } else {
      calEventContent = (
        <div>
          <CalEventItem calEvent={calEvent} />
        </div>
      );
    }

    return (
      <div className="cal-events">
        <div className="row">
          <div className="col-md-12">
            <Link to="/" className="btn btn-light mb-3">
              Home
            </Link>
            {calEventContent}
          </div>
        </div>
      </div>
    );
  }
}

OneCalEvent.propTypes = {
  getcalEvent: PropTypes.func.isRequired,
  calEvent: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  calEvent: state.calEvent
});

export default connect(
  mapStateToProps,
  { getcalEvent }
)(OneCalEvent);
