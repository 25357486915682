import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
// import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {deleteCalEvent} from '../../actions/calEventActions';

class CalEventItem extends Component {

  onDeleteClick = id => {
    this.props.deleteCalEvent(id);
  };

  render() {
    const { calEvent, auth } = this.props;

    return (
      <div className="container">
        <ul className="list-group text-center">
          <li className="list-group-item active">
            <Link to={`/cal-events/${calEvent._id}`} className="btn btn-info">
              {calEvent.title}
            </Link>
            {calEvent.userCreated === auth.user.id ? (
              <button 
                type="button"
                onClick={() => this.onDeleteClick(calEvent._id)}
                className="btn btn-danger float-right"
              >
                <i className="fas fa-times" />
              </button>
            ): null}
          </li>
          <li className="list-group-item">
            <p>{calEvent.description}</p>
          </li>
          <li className="list-group-item">
            <span className="text-info">
              Where:
            </span> <br />
            {calEvent.address} <br />
            {calEvent.city} <br />
            {calEvent.state} <br />
            {calEvent.zip}
          </li>
          <li className="list-group-item">
            {calEvent.startDate ? (
              <Moment format="MMMM Do YYYY @ h:mm a" element="span" className="text-info">
                {calEvent.startDate} 
              </Moment>
            ): <span className="text-info">No Date Entered</span>}
          </li>
          <li className="list-group-item">
            {calEvent.endDate ? (
              <Moment format="MMMM Do YYYY @ h:mm a" element="span" className="text-info">
                {calEvent.endDate} 
              </Moment>
            ): <span className="text-info">No Date Entered</span>}
          </li>
        </ul>
        <br/>
      </div>
    )
  }
};

CalEventItem.propTypes = {
  deleteCalEvent: PropTypes.func.isRequired,
  calEvent: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {deleteCalEvent})(CalEventItem);
