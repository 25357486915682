import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextFieldGroup from '../common/TextFieldGroup';
import TextAreaFieldGroup from '../common/TextAreaFieldGroup';
import { addCalEvent } from '../../actions/calEventActions';

class CalEventForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      address: '',
      city: '',
      state: '',
      zip:'',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      errors: {}
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.errors !== nextProps.errors) {
      return { errors: nextProps.errors };
    }
    return null;
  };

  onSubmit = e => {
    e.preventDefault();
    const { user } = this.props.auth;

    const newCalEvent = {
      userCreated: user.id,
      title: this.state.title,
      description: this.state.description,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };

    // console.log(newCalEvent);

    this.props.addCalEvent(newCalEvent);

    this.setState({
      title: '',
      description: '',
      address: '',
      city: '',
      state: '',
      zip:'',
      startDate: '',
      endDate: ''
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };



  render() {
    const { errors } = this.state;

    return (
      <div className="create-calevent">
        <div className="container">
          <p className="lead text-center">
            Create a new Calendar Event
          </p>
          <small className="d-block pb-3">* = required fields</small>
          <form onSubmit={this.onSubmit}>
            <TextFieldGroup
              placeholder="title"
              name="title"
              value={this.state.title}
              onChange={this.onChange}
              error={errors.title}
              info="Title for the Event"
            />
            <TextFieldGroup
              placeholder="Address"
              name="address"
              value={this.state.address}
              onChange={this.onChange}
              error={errors.address}
              info="Street Address"
            />
            <div className="form-row">
              <div className="col">
                <TextFieldGroup
                  placeholder="City"
                  name="city"
                  value={this.state.city}
                  onChange={this.onChange}
                  error={errors.city}
                  info="City"
                />
              </div>
              <div className="col">
                <TextFieldGroup
                  placeholder="State"
                  name="state"
                  value={this.state.state}
                  onChange={this.onChange}
                  error={errors.state}
                  info="State"
                />
              </div>
              <div className="col">
                <TextFieldGroup
                  placeholder="Zip Code"
                  name="zip"
                  value={this.state.zip}
                  onChange={this.onChange}
                  error={errors.zip}
                  info="Zip Code"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="col">
                <TextFieldGroup
                  placeholder="Starting Date and Time"
                  name="startDate"
                  value={this.state.startDate}
                  type="datetime-local"
                  onChange={this.onChange}
                  error={errors.startDate}
                  info="Event Starting Date "
                />
              </div>
              <div className="col">
                <TextFieldGroup
                  placeholder="Ending Date and Time"
                  name="endDate"
                  value={this.state.endDate}
                  type="datetime-local"
                  onChange={this.onChange}
                  error={errors.endDate}
                  info="Event Ending Date "
                />
              </div>
            </div>

            <TextAreaFieldGroup
              placeholder="Descriptin"
              name="description"
              value={this.state.description}
              onChange={this.onChange}
              error={errors.description}
              info="Descriptin of Event"
              rows="7"
            />
            <input
              type="submit"
              value="Submit"
              className="btn btn-info btn-block my-4"
            />
          </form>
        </div>
      </div>
    )
  }
};

CalEventForm.propTypes = {
  calEvent: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  calEvent: state.calEvent,
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { addCalEvent }
)(CalEventForm);
