import {
  ADD_CALEVENT,
  GET_CALEVENTS,
  GET_CALEVENT,
  CALEVENT_LOADING,
  DELETE_CALEVENT
} from '../actions/types';

const initalState = {
  calEvents: [],
  calEvent: {},
  loading: false
};

export default function(state = initalState, action) {
  switch (action.type) {
    case CALEVENT_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_CALEVENTS:
      return {
        ...state,
        calEvents: action.payload,
        loading: false
      };
    case GET_CALEVENT:
      return {
        ...state,
        calEvent: action.payload,
        loading: false
      };
    case ADD_CALEVENT:
      return {
        ...state,
        calEvents: [action.payload, ...state.calEvents]
      };
    case DELETE_CALEVENT:
      return {
        ...state,
        calEvents: state.calEvents.filter(calEvent => calEvent._id !== action.payload)
      };
    default:
      return state;
  }
}
