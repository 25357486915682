import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GuestLanding from './GuestLanding';
import AuthLanding from './AuthLanding';

class Landing extends Component {
  render() {
    const { isAuthenticated } = this.props.auth;
   
    return (
      <div className="landing">
        <div className="logo_pic img-fluid"/>
        <div className="row">
          <div className="col-lg-10">
              {isAuthenticated ? <AuthLanding />: <GuestLanding />}
          </div>
          <div className="col-lg-2">
            <div className="list-group m-1 text-center border border-secondary rounded p-2 bg-primary">
              <li className="list-group-item active">Helpful Links</li>
              <a href="https://www.sdhc.k12.fl.us/" target="_blank"  rel="noopener noreferrer" className="list-group-item list-group-item-action">Public Schools</a>
              <a href="https://www.floridasmart.com/local/counties/hillsborough/education_private.htm" target="_blank" rel="noopener noreferrer" className="list-group-item list-group-item-action">Private Schools</a>
              <a href="https://www.tampabay.com/sports/" target="_blank"  rel="noopener noreferrer" className="list-group-item list-group-item-action">Tampa Bay Sports</a>
              <a href="http://tampabaseballmuseum.org/events/" target="_blank"  rel="noopener noreferrer" className="list-group-item list-group-item-action">Tampa Baseball Museum</a>
              <a href="http://www.tampasportsclub.org/" target="_blank"  rel="noopener noreferrer" className="list-group-item list-group-item-action">Sports Club of Tampa Bay</a>
              <a href="http://tampabaseballmuseum.org/events/" target="_blank"  rel="noopener noreferrer" className="list-group-item list-group-item-action">Tampa Baseball Museum</a>
              <a href="https://www.condoconceptsllc.com/park-place-realty" target="_blank"  rel="noopener noreferrer" className="list-group-item list-group-item-action">
              <img
                className="img-thumbnail img-fluid"
                src={require('../../img/logo_sell_buy_real_estate.png')}
                alt="Park Place Realty"
              /></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Landing);
