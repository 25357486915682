import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CalEvents from '../calEvents/CalEvents';
import ManagerCreateEvent from './ManagerCreateEvent';
import Photos from '../photos/Photos';
import ManagerUploadImage from './ManagerUploadImage';

class ManagerDashboard extends Component {
  render() {
    return (
      <div className="manager-dashboard">
        <Link to="/" className="btn btn-light m-4">
          Home
        </Link>
        <h1 className="display-4 text-center">Manager Dashboard</h1>
          <div className="container">
            <p className="text-center">Manager page for sending email notices for group events, updating photo gallery, and sending event notices.</p>
          </div>
        <div className="row">
          <div className="col-md-4">
            <CalEvents />
          </div>
          <div className="col-md-8">
            <ManagerCreateEvent />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <Photos />
            </div>
          </div>
          <div className="col-md-12">
            <ManagerUploadImage />
          </div>
        </div>
      </div>
    )
  }
}

export default ManagerDashboard;
