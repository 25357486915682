import React from 'react';
import { Link } from 'react-router-dom';

const CoachHistory = () => {
  return (
    <div className="history">
      <div className="container">
        <div className="row">
          <Link to="/" className="btn btn-light my-2">
            Go Back
          </Link>
          <div className="col-md-12 text-center">
            <h1 className="display-3 mb-3">Our History</h1>
            <p className="lead">
              In the beginning retired coaches sat and watched the games on
              TV.
            </p>
            <p>
              Life was good. One day Richard Dyer said, let there be
              breakfast, and there was breakfast. Richard said, let us gather
              at the West Tampa Sandwich Shop to talk about the good old days.
              There was Jan Bennett, David Fyfe, Dale Klay, Frank Lentz, Dan
              Marczynski, Dick Pusins, Dave Small, Ken Sweeney and David
              Toward. There was green eggs and there was ham. They saw that
              the breakfast was good.
            </p>
            <p>
              David Fyfe said, next time, I will invite Charlie Kerr. Charlie
              heard the names of the coaches that gathered and exclaimed,
              those are the Coaching Legends! The coaches saw that the name
              was good.
            </p>
            <p>
              Ken Sweeney said, let us meet to eat at a more central location,
              and it was so. Perkins Restaurant on Bearss Avenue was good.
            </p>
            <p>
              The coaches said, let us have breakfast and invite more of our
              various kind and make Dick Pusins the facilitator, and it was
              so. More coaches came to the larger restaurant called Brunchie's
              on North Dale Mabry. The coaches saw that the breakfast was
              good.
            </p>
            <p>
              The Coaching Legends said, let us be fruitful and give back to
              the community, and it was so. Donations of food, clothing and
              other goods increased in number.
            </p>
            <p>
              The Coaching Legends saw all that they had made, and it was very
              good.
            </p>
          </div>
        </div>
        <hr />
        <h2 className="display-4 mb-2">Meet the Founders</h2>
        <div className="row text-center">
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/founders/bennett_jan.jpg')}
                alt="Jan Bennett"
              />
              <div className="card-body">
                <p className="card-text">Jan Bennett</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/founders/dyer_richard.jpg')}
                alt="Richard Dyer"
              />
              <div className="card-body">
                <p className="card-text">Richard Dyer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/founders/fyfe_david.jpg')}
                alt="David Fyfe"
              />
              <div className="card-body">
                <p className="card-text">David Fyfe</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/founders/klay_dale.jpg')}
                alt="Dale Klay"
              />
              <div className="card-body">
                <p className="card-text">Dale Klay</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/founders/lentz_frank.jpg')}
                alt="Frank Lentz"
              />
              <div className="card-body">
                <p className="card-text">Frank Lentz</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/founders/marcynski_dan.jpg')}
                alt="Dan Marcynski"
              />
              <div className="card-body">
                <p className="card-text">Dan Marcynski</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/founders/pusins_dick.jpg')}
                alt="Dick Pusins"
              />
              <div className="card-body">
                <p className="card-text">Dick Pusins</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/founders/small_dave.jpg')}
                alt="Dave Small"
              />
              <div className="card-body">
                <p className="card-text">Dave Small</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/founders/sweeney_ken.jpg')}
                alt="Ken Sweeney"
              />
              <div className="card-body">
                <p className="card-text">Ken Sweeney</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/founders/toward_david.jpg')}
                alt="David Toward"
              />
              <div className="card-body">
                <p className="card-text">David Toward</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachHistory;
