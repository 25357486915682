import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUsers } from '../../actions/adminActions';
import Spinner from '../common/Spinner';

class AdminDashboard extends Component {
  componentDidMount() {
    this.props.getUsers();
  };

  render() {
    const { users, loading } = this.props.admin;
    if(users === null || loading) {
      return <Spinner />
    } else {
      return (
        <div className="admin-dashboard">
          <Link to="/dashboard" className="btn btn-light">
            Go Back
          </Link>
          <h1 className="display-4 text-center">
            Admin Dashboard
          </h1>
          <div className="row">
            <div className="col-md-12">
              <p>User permissions, update, removing, etc.  All App wide admin previliges.</p>
              <div className="users-list">
                <div className="card">
                  <div className="card-header text-center">
                    Manager User Roles
                  </div>
                  <ul className="list-group list-group-flush d-flex">
                  {users.map(user => (
                    <li key={user._id} className="list-group-item justify-content-center">
                      <div className="form-row">
                        <div className="col">
                          <span>{user.name}</span>
                        </div>
                        <div className="col">
                        <span>{user.email}</span>
                        </div>
                        <div className="col">
                          <span>Current Role is <span className="text-info font-italic">{user.role}</span></span>
                        </div>
                        <div className="col">
                          <Link className="btn btn-outline-success" to={`/admin/user/${user._id}`}>Update User Role</Link>
                        </div>
                      </div>
                    </li>
                  ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

AdminDashboard.propTypes = {
  getUsers: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  admin: state.admin
});

export default connect(mapStateToProps, {getUsers})(AdminDashboard);
