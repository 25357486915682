import React from 'react';
import {Link} from 'react-router-dom';
import QuoteSlider from '../common/QuoteSlider';
import Posts from '../posts/Posts';
import CalEvents from '../calEvents/CalEvents';

const AuthLanding = () => {
  return (
    <div className="auth-landing p-2">
      <div className="row">
        <div className="col-md-3">
          <div className="d-flex flex-column">
            <Link className="w-75 mb-2" to='/memory'>
              <img
                style={{width: '10rem', height: '5rem'}}
                className="border border-secondary img-fluid img-thumbnail"
                src={require('../../img/memory/memory_banner.jpg')}
                alt="In Memory"
                />
            </Link>
            <Link className="text-center btn btn-primary" to='/photos'>
              Photo Gallary
            </Link>
          </div>
          <div className="card mt-2">
            <div className="card-body text-center">
              <h5 className="card-title text-info text-uppercase">Meet for Breakfast</h5>
              <h6 className="card-subtitle mt-2 text-muted">When: </h6>
              <p>The 1st <span className="text-uppercase text-info">Wednesday</span> of each Month at 8:30am. Map link below..</p>
              <h6 className="card-subtitle mt-2 text-muted">Where: </h6>
              <p className="card-text">
                <a  target="_blank"  rel="noopener noreferrer" href="http://brunchiestampa.com/">Brunchies</a> <br />
                14366<br />
                N. Dale Mabry <br />
                Tampa Fl. <br />
                33618
              </p>
              <a target="_blank"  rel="noopener noreferrer" href="https://www.google.com/maps/place/14366+N+Dale+Mabry+Hwy,+Palms+of+Carrollwood+Shopping+Center,+Greater+Carrollwood,+FL+33618/@28.0782845,-82.5082003,17z/data=!3m1!4b1!4m2!3m1!1s0x88c2c08e1b123cdd:0xa54af2088ecd7af2" className="btn btn-outline-info">Map It</a>
            </div>
          </div>
          <CalEvents />
        </div>
        <div className="col-md-9">
          <QuoteSlider />
          <Posts />
        </div>
      </div>
    </div>
  )
}

export default AuthLanding
