import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
// import classnames from 'classnames';
import {deletePhoto} from '../../actions/photoActions';

class PhotoItem extends Component {

  onDeleteClick = id => {
    this.props.deletePhoto(id);
  };

  render() {
    const { photo, auth } = this.props;

    return (
      <div className="col-md-3 mb-2">
        <div className="card photo-item">
          {photo.userCreated === auth.user.id ? (
              <button 
                type="button"
                onClick={() => this.onDeleteClick(photo._id)}
                className="btn btn-danger float-right"
              >
                <i className="fas fa-times" />
              </button>
            ): null}
          <img
            className="card-img-top"
            src={photo.url}
            alt=""
          />
          <div className="card-body">
            <p className="card-text">
            {photo.caption}
            <br />
              <Moment format="MMMM Do YYYY" element="small" className="text-info">
                {photo.date}
              </Moment>
            </p>
          </div>
        </div>
      </div>
    )
  }
};

PhotoItem.propTypes = {
  deletePhoto: PropTypes.func.isRequired,
  photo: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {deletePhoto})(PhotoItem);
