import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CalEventItem from './CalEventItem';

class CalEventsFeed extends Component {
  render() {
    const { calEvents } = this.props;

    return calEvents.map(calEvent => <CalEventItem key={calEvent._id} calEvent={calEvent} />);
  }
}

CalEventsFeed.propTypes = {
  calEvents: PropTypes.array.isRequired
};

export default CalEventsFeed;