import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import isEmpty from '../../validation/is-empty';

class ProfileItem extends Component {
  render() {
    const { profile } = this.props;
    const { isAuthenticated } = this.props.auth;

    const defaultProfileImg = (
      <img
        className="img-fluid rounded-circle"
        src={require('../../img/default_profile.png')}
        alt={profile.user.name}
        title={profile.user.name}
      />
    );

    const uploadedProfileImg = (
      <img
        className="img-fluid rounded-circle"
        src={profile.user.avatar}
        alt={profile.user.name}
        title={profile.user.name}
      />
    );

    return (
      <div className="card card-body bg-light mb-3">
        <div className="row">
          <div className="col-2">
            {profile.user.avatar ? uploadedProfileImg : defaultProfileImg}
          </div>
          <div className="col-lg-6 col-md-4 col-8">
            <h3>{profile.user.name}</h3>
            <p>
              {profile.status}{' '}
              {isEmpty(profile.company) ? null : (
                <span>at {profile.company}</span>
              )}
            </p>
            <p>
              {isEmpty(profile.location) ? null : (
                <span>{profile.location}</span>
              )}
            </p>
            {isAuthenticated ? (
              <Link to={`/profile/${profile.handle}`} className="btn btn-info">
                View Profile
              </Link>
            ) : null}
          </div>
          <div className="col-md-4 d-none d-block">
            <h4>Favorite Sports and Teams</h4>
            <ul className="list-group">
              {profile.favsports.slice(0, 4).map((favsport, index) => (
                <li key={index} className="list-group-item">
                  <i className="fas fa-check pr-1" />
                  {favsport}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ProfileItem);
