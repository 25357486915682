import React, { Component } from 'react';
import PhotoForm from '../photos/PhotoForm';

class ManagerUploadImage extends Component {
  render() {
    return (
      <div>
        <h1 className="text-center">Photo Upload</h1>
        <PhotoForm />
      </div>
    )
  }
}

export default ManagerUploadImage;
