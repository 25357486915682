import React from 'react';
import {Link} from 'react-router-dom';

const InMemory = () => {
  return (
    <div className="memory"> 
      <div className="container">
        <Link to="/" className="btn btn-light">
          Go Back
        </Link>
        <h1 className="display-2 mb-2">In Memory</h1>
        <p className="lead">Life is the dash (-) that represents the time between your birth date, and the date you leave this earth. The dash is filled with dreams achievements, gratitudes, forgiveness, successes, goals, passions, fulfillments, joys, service, kindness, goodness, encouragements, laughter, sorrow, happiness, and the unseen deeds that changed someone’s life for the better.</p>
        <p className="font-italic">The family and friends left behind will always remember the importance of the dash.</p>
        <div className="row">
          <div className="col-6">
            <ul className="list-group p-4">
              <li className="list-group-item">
                <div className="media">
                <img
                    style={{width: '200px', height: '200px'}}
                    className="img-thumbnail mr-2"
                    src={require('../../img/memory/durnin_jerry.jpg')}
                    alt="Jerry Durnin"
                  />
                  <div className="media-body">
                    <h5 className="mt-0">Jerry Durnin</h5>
                    <p>1929-2013</p>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
              <div className="media">
                <img
                    style={{width: '200px', height: '200px'}}
                    className="img-thumbnail mr-2"
                    src={require('../../img/memory/minahan_bill.jpg')}
                    alt="Bill Minahan"
                  />
                  <div className="media-body">
                    <h5 className="mt-0">Bill Minahan</h5>
                    <p>1929-2013</p>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
              <div className="media">
                <img
                    style={{width: '200px', height: '200px'}}
                    className="img-thumbnail mr-2"
                    src={require('../../img/memory/marcynski_dan.jpg')}
                    alt="Dan Marczymski"
                  />
                  <div className="media-body">
                    <h5 className="mt-0">Dan Marczymski</h5>
                    <p>1947-2013</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-6">
          <ul className="list-group p-4"> 
            <li className="list-group-item">
              <div className="media">
                <img
                    style={{width: '200px', height: '200px'}}
                    className="img-thumbnail mr-2"
                    src={require('../../img/memory/sussman_vince.png')}
                    alt="Vincent Sussman"
                  />
                  <div className="media-body">
                    <h5 className="mt-0">Vincent Sussman</h5>
                    <p>1949-2013</p>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
              <div className="media">
                <img
                    style={{width: '200px', height: '200px'}}
                    className="img-thumbnail mr-2"
                    src={require('../../img/memory/hicks_john.png')}
                    alt="John Hicks"
                  />
                  <div className="media-body">
                    <h5 className="mt-0">John Hicks</h5>
                    <p>1942-2016</p>
                  </div>
                </div>
              </li>
              <li className="list-group-item">
              <div className="media">
                <img
                    style={{width: '200px', height: '200px'}}
                    className="img-thumbnail mr-2"
                    src={require('../../img/default_profile.png')}
                    alt="John Owens"
                  />
                  <div className="media-body">
                    <h5 className="mt-0">John Owens</h5>
                    <p>1942-2016</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default InMemory;
