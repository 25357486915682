import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PhotosFeed from './PhotosFeed';
import Spinner from '../common/Spinner';
import { getPhotos } from '../../actions/photoActions';

class Photos extends Component {
  componentDidMount() {
    this.props.getPhotos();
  }

  render() {
    const { photos, loading } = this.props.photo;
    let photoContent;

    if (photos === null || loading) {
      photoContent = <Spinner />;
    } else {
      photoContent = <PhotosFeed photos={photos} />;
    }

    return (
      <div className="feed">
        <div className="text-center">
          <div className="row">
            {photoContent}
          </div>
        </div>
      </div>
    );
  }
}

Photos.protoTypes = {
  getPhotos: PropTypes.func.isRequired,
  photo: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  photo: state.photo
});

export default connect(
  mapStateToProps,
  { getPhotos }
)(Photos);