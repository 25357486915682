import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextAreaFieldGroup from '../common/TextAreaFieldGroup';
import { addPhoto } from '../../actions/photoActions';

class PhotoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caption: '',
      url: ''
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.errors !== nextProps.errors) {
      return { errors: nextProps.errors };
    }
    return null;
  };

  onSubmit = e => {
    e.preventDefault();
    const { user } = this.props.auth;

    const newPhoto = {
      userCreated: user.id,
      caption: this.state.caption,
      url: this.state.url
    };

    // console.log(newCalEvent);

    this.props.addPhoto(newPhoto);

    this.setState({
      caption: '',
      url: ''
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnChange = async e => {
    const files = e.target.files;
    const data = new FormData();
    data.append('file', files[0]);
    data.append('upload_preset', 'coachingprofile');

    const res = await fetch(
      'https://api.cloudinary.com/v1_1/z00ie-test-cloud/upload',
      {
        method: 'POST',
        body: data
      }
    );
    const file = await res.json();
    // console.log(file);
    this.setState({
      url: file.secure_url
    });
  };

  render() {
    return (
      <div className="upload-coaching-photo my-5">
        <div className="container">
        <p className="lead text-center">
            Upload New Photo
          </p>
          <form onSubmit={this.onSubmit}>
            <TextAreaFieldGroup
                placeholder="Caption"
                name="caption"
                value={this.state.caption}
                onChange={this.onChange}
                info="Add a caption"
                rows="7"
              />
            <div className="input-group mb-3">
            
              <div className="custom-file">
                <label className="custom-file-label" htmlFor="photo-upload">
                  Choose file
                </label>
                <input
                  onChange={this.handleOnChange}
                  name="avatar"
                  type="file"
                  className="custom-file-input"
                  id="photo-upload"
                />
              </div>
            </div>
            <input
              value="Upload"
              type="submit"
              className="btn btn-info btn-block"
            />
          </form>
          {this.state.url && (
            <img
              className="img-thumbnail"
              style={{ width: '250px', height: '250px' }}
              src={this.state.url}
              alt="Upload Preview"
            />
          )}
        </div>
      </div>
    );
  }
};

PhotoForm.propTypes = {
  addPhoto: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  photo: state.photo,
  auth: state.auth
});

export default connect(mapStateToProps, {addPhoto})(PhotoForm);