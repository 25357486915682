import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile, deleteAccount } from '../../actions/profileActions';
import Spinner from '../common/Spinner';
import ProfileActions from './ProfileActions';
import Experience from './Experience';
import Education from './Education';
import YouTube from './YouTube';

class Dashboard extends Component {
  componentDidMount() {
    this.props.getCurrentProfile();
  }

  onDeleteClick = e => {
    this.props.deleteAccount();
  };

  render() {
    const { user } = this.props.auth;
    const { profile, loading } = this.props.profile;

    const defaultProfileImg = (
      <img
        className="rounded img-fluid img-thumbnail"
        src={require('../../img/default_profile.png')}
        alt={user.name}
        title={user.name}
        style={{ width: '200px', height: '200px' }}
      />
    );

    const uploadedProfileImg = (
      <img
        className="rounded img-fluid img-thumbnail"
        src={user.avatar}
        alt={user.name}
        title={user.name}
        style={{ width: '200px', height: '200px' }}
      />
    );

    let dashboardContent;

    if (profile === null || loading) {
      dashboardContent = <Spinner />;
    } else {
      // Check if logged in user has profile data
      if (Object.keys(profile).length > 0) {
        dashboardContent = (
          <>
            <div className="d-flex flex-row align-items-center">
              <h1 className="display-4">
                <Link to={`/profile/${profile.handle}`}>{user.name}</Link>
              </h1>
              <button
                type="button"
                onClick={this.onDeleteClick}
                className="btn btn-danger ml-4"
              >
                Delete My Account
              </button>
            </div>
            <div className="row">
              <div className="col-md-9">
                <div className="card my-2 bg-light">
                  <div className="card-body">
                    <p className="lead text-center text-info card-text">
                      User settings
                    </p>
                    <hr />
                    <div className="row">
                      <div className="col-md-4">
                        {user.avatar ? uploadedProfileImg : defaultProfileImg}
                      </div>
                      <div className="col-md-8">
                        <ProfileActions />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <p>Something here.....</p>
              </div>
            </div>
            <Experience experience={profile.experience} />
            <Education education={profile.education} />
            <hr />
            <YouTube youtubevideos={profile.youtubevideos} /> 
          </>
        );
      } else {
        // User is logged in but has no profile
        dashboardContent = (
          <div>
            <p className="lead text-muted">Welcome {user.name}</p>
            <p>You have not yet setup a profile, please add some info</p>
            <Link to="/create-profile" className="btn btn-lg btn-info">
              Create Profile
            </Link>
          </div>
        );
      }
    }

    return <div className="dashboard">{dashboardContent}</div>;
  }
}

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, deleteAccount }
)(Dashboard);
