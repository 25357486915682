import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CalEventsFeed from './CalEventsFeed';
import Spinner from '../common/Spinner';
import { getCalEvents } from '../../actions/calEventActions';

class Calevents extends Component {
  componentDidMount() {
    this.props.getCalEvents();
  }

  render() {
    const { calEvents, loading } = this.props.calEvent;
    let calEventContent;

    if (calEvents === null || loading) {
      calEventContent = <Spinner />;
    } else {
      calEventContent = <CalEventsFeed calEvents={calEvents} />;
    }

    return (
      <div className="feed">
        <div className="text-center">
          <h1>Upcoming Events</h1>
          {calEventContent}
        </div>
      </div>
    );
  }
}

Calevents.protoTypes = {
  getCalEvents: PropTypes.func.isRequired,
  calEvent: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  calEvent: state.calEvent
});

export default connect(
  mapStateToProps,
  { getCalEvents }
)(Calevents);
