import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { uploadProfilePic } from '../../actions/authActions';

export class UploadProfilePic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: ''
    };
  }

  onSubmit = e => {
    e.preventDefault();

    const newPic = {
      avatar: this.state.avatar
    };

    this.props.uploadProfilePic(newPic, this.props.history);
  };

  handleOnChange = async e => {
    const files = e.target.files;
    const data = new FormData();
    data.append('file', files[0]);
    data.append('upload_preset', 'coachingprofile');

    const res = await fetch(
      'https://api.cloudinary.com/v1_1/z00ie-test-cloud/upload',
      {
        method: 'POST',
        body: data
      }
    );
    const file = await res.json();
    // console.log(file);
    this.setState({
      avatar: file.secure_url
    });
  };

  render() {
    return (
      <div className="upload-profile-pic">
        <Link to="/dashboard" className="btn btn-light">
          Go Back
        </Link>
        <h1 className="display-4 text-center">Upload Profile Pic</h1>
        <div className="row">
          <div className="col-md-5">
            <form onSubmit={this.onSubmit}>
              <div className="input-group mb-3">
                <div className="custom-file">
                  <label className="custom-file-label" htmlFor="profile-upload">
                    Choose file
                  </label>
                  <input
                    onChange={this.handleOnChange}
                    name="avatar"
                    type="file"
                    className="custom-file-input"
                    id="profile-upload"
                  />
                </div>
              </div>
              <input
                value="Confirm"
                type="submit"
                className="btn btn-primary btn-block"
              />
            </form>
            {this.state.avatar && (
              <img
                className="img-thumbnail"
                style={{ width: '250px', height: '250px' }}
                src={this.state.avatar}
                alt="Upload Preview"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

UploadProfilePic.protoTypes = {
  uploadProfilePic: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { uploadProfilePic }
)(withRouter(UploadProfilePic));
