import React from 'react'
import QuoteSlider from '../common/QuoteSlider';
import Login from '../auth/Login';
import Register from '../auth/Register';
import ResetPass from '../auth/ResetPass';

const GuestLanding = () => {
  return (
    <>
    <div className="text-center">
        <p className="lead text-primary">
        Create a coaches profile/portfolio, share posts, pictures, &
        youtube videos. Come socialize with friends, old and new.
        </p>
        <hr />
    </div>
    <div className="row">
        <div className="col-md-8">
            <div className="tab-content" id="nav-tabContent">
                <div
                    className="tab-pane fade show active p-4"
                    id="list-home"
                    role="tabpanel"
                    aria-labelledby="list-home"
                >
                    <h5 className="text-primary">WELCOME</h5>
                    <p className="card-text text-dark">
                    Welcome to the Coaching Legends of Hillsborough County, Florida.
                    Retired coaches and friends that are associated with sports in
                    the Tampa Bay Area. They gather as colleagues to renew and
                    maintain old friendships, share rich experiences and enjoy
                    laughs. The Legends has grown to more than 200 coaches with an
                    open invitation to all other coaches and friends.
                    </p>
                    <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#landingPageModal"
                    >
                    Read our full Story...
                    </button>
                    <div
                    className="modal fade bd-example-modal-lg"
                    id="landingPageModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="landingPageModalTitle"
                    aria-hidden="true"
                    >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="landingPageModalTitle">
                                Welcome
                                </h5>
                                <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        <div className="modal-body">
                            <p>
                            Welcome to the Coaching Legends of Hillsborough County,
                            Florida. Retired coaches and friends that are associated
                            with sports in the Tampa Bay Area meet for breakfast the
                            first Wednesday of each month. They gather as colleagues
                            to renew and maintain old friendships, share rich
                            experiences and enjoy laughs. The Legends has grown to
                            more than 200 coaches with an open invitation to all
                            other coaches and friends. In June 2015, the coaches
                            celebrated their five year anniversary.
                            </p>
                            <p>
                            Collectively, these coaches have won more than 10,000
                            games and have over 300 years of combined experience and
                            tons of stories to reminisce the good old coaching days.
                            They have touched the lives of thousands of athletes.
                            Their knowledge, passion and love of the games continue
                            to have an impact today.
                            </p>
                            <p>
                            In addition to the monthly social gathering, the
                            Coaching Legends give back to the community with
                            donations of food, clothing and other goods. 100% of all
                            donations go directly to community service and charity!
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div
                    className="tab-pane fade"
                    id="list-login"
                    role="tabpanel"
                    aria-labelledby="list-login"
                >
                    <Login />
                </div>
                <div
                    className="tab-pane fade"
                    id="list-signup"
                    role="tabpanel"
                    aria-labelledby="list-signup"
                >
                    <Register />
                </div>
                <div
                    className="tab-pane fade"
                    id="list-reset-pass"
                    role="tabpanel"
                    aria-labelledby="list-reset-pass"
                >
                    <ResetPass />
                </div>
            </div>
        </div>

        <div className="col-md-4">
            <p className="font-italic mt-2 text-primary">
                Come see what we have done together....
            </p>
            <div className="list-group" id="list-tab" role="tablist">
                <a
                    className="list-group-item list-group-item-action active"
                    id="list-home"
                    data-toggle="list"
                    href="#list-home"
                    role="tab"
                    aria-controls="home"
                >
                    Home
                </a>
                <a
                    className="list-group-item list-group-item-action"
                    id="list-login"
                    data-toggle="list"
                    href="#list-login"
                    role="tab"

                    aria-controls="login"
                >
                    LogIn
                </a>
                <a
                    className="list-group-item list-group-item-action"
                    id="list-signup"
                    data-toggle="list"
                    href="#list-signup"
                    role="tab"
                    aria-controls="signup"
                >
                    SignUp
                </a>
                <a
                    className="list-group-item list-group-item-action"
                    id="list-reset-pass"
                    data-toggle="list"
                    href="#list-reset-pass"
                    role="tab"

                    aria-controls="reset-pass"
                >
                    Request to Reset Password
                </a>
            </div>
        </div>
    </div>
    <div className="m-2">
        <QuoteSlider />
    </div>
</>
  )
}

export default GuestLanding;
