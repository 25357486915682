import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileActions';

class Navbar extends Component {
  onLogoutClick = e => {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;

    const managerBtn = (
      <Link to="/manager" className="nav-link text-info">
        Manager Dash
      </Link>
    );

    const siteAdminBtn = (
      <Link to="/admin" className="nav-link text-info">
        Admin Dash
      </Link>
    );

    const defaultProfileImg = (
      <img
        className="img-fluid rounded-circle"
        src={require('../../img/default_profile.png')}
        alt={user.name}
        style={{ width: '25px', marginRight: '5px' }}
        title=""
      />
    );

    const uploadedProfileImg = (
      <img
        className="img-fluid rounded-circle"
        src={user.avatar}
        alt={user.name}
        style={{ width: '25px', marginRight: '5px' }}
        title=""
      />
    );

    const authLinks = (
      <ul className="navbar-nav ml-auto ">
        <li className="nav-item">
          <Link className="nav-link" to="/feed">
            Post Feed
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            Dashboard
          </Link>
        </li>
        {(isAuthenticated && (user.role === 'Manager' || user.role === 'Admin')) ? (
          (user.role === 'Admin' ? (
            <>
            <li className="nav-item">
              {siteAdminBtn}
            </li>
            <li className="nav-item">
              {managerBtn}
            </li>
            </>
          ) : <li className="nav-item">{managerBtn}</li>
        )) : null}
        <li className="nav-item">
          <button
            type="button"
            onClick={this.onLogoutClick}
            className="btn btn-outline-dark text-muted"
          >
            {user.avatar ? uploadedProfileImg : defaultProfileImg}
            Logout
          </button>
        </li>
      </ul>
    );

    const guestLinks = '';

    return (
      <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dark py-3">
        <Link className="navbar-brand" to="/">
          {' '}
          Coaching Legends
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#mobile-nav"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="mobile-nav">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/profiles">
                {' '}
                Coaches
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/history">
                {' '}
                History
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/service">
                {' '}
                Community Service
              </Link>
            </li>
          </ul>
          {isAuthenticated ? authLinks : guestLinks}
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser, clearCurrentProfile }
)(Navbar);
