export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const POST_LOADING = 'POST_LOADING';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';
export const ADD_USER = 'ADD_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const USER_LOADING = 'USER_LOADING';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_CALEVENT = 'ADD_CALEVENT';
export const GET_CALEVENTS = 'GET_CALEVENTS';
export const GET_CALEVENT = 'GET_CALEVENT';
export const CALEVENT_LOADING = 'CALEVENT_LOADING';
export const DELETE_CALEVENT = 'DELETE_CALEVENT';
export const ADD_PHOTO = 'ADD_PHOTO';
export const GET_PHOTOS = 'GET_PHOTOS';
export const GET_PHOTO = 'GET_PHOTO';
export const PHOTO_LOADING = 'PHOTO_LOADING';
export const DELETE_PHOTO = 'DELETE_PHOTO';