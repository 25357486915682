import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="footer bg-dark mt-5 text-white text-center">
        Copyright &copy; {new Date().getFullYear()} Coaching Legends
      </footer>
    );
  }
}

export default Footer;
