import axios from 'axios';

import {
  ADD_PHOTO,
  GET_PHOTOS,
  GET_PHOTO,
  PHOTO_LOADING,
  DELETE_PHOTO,
  GET_ERRORS,
  CLEAR_ERRORS 
} from './types.js';

// Add a photo 
export const addPhoto = (photoData) => dispatch => {
  dispatch(clearErrors());
  axios.post('/api/photos', photoData)
    .then(res =>
      dispatch({
        type: ADD_PHOTO,
        payload: res.data
      })  
    )
    .catch(err => 
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })    
    );
};

// Get all photos 
export const getPhotos = () => dispatch => {
  dispatch(setPhotoLoading());
  axios.get('/api/photos')
    .then(res =>
      dispatch({
        type: GET_PHOTOS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PHOTOS,
        payload: null
      })  
    );
};

// Get one photo 
export const getPhoto = id => dispatch => {
  dispatch(setPhotoLoading());
  axios.get(`/api/photos/${id}`)
    .then(res =>
      dispatch({
        type: GET_PHOTO,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PHOTO,
        payload: null
      })
    );
};

// Delete Photo
export const deletePhoto = id => dispatch => {
  axios.delete(`/api/photos/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_PHOTO,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Set Loading state
export const setPhotoLoading = () => {
  return {
    type: PHOTO_LOADING
  };
};

// Clear Errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
