import axios from 'axios';

import {
  // ADD_USER,
  UPDATE_USER,
  GET_USERS,
  GET_USER,
  USER_LOADING,
  // DELETE_USER,
  GET_ERRORS
} from './types';

// Get all Users 
export const getUsers = () => dispatch => {
  dispatch(setUserLoading());
  axios
    .get('/api/admin/users')
    .then(res => 
      dispatch({
        type: GET_USERS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get all Users 
export const getUser = (id) => dispatch => {
  dispatch(setUserLoading());
  axios
    .get(`/api/admin/user/${id}`)
    .then(res => 
      dispatch({
        type: GET_USER,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Update Permissions
export const updateUser = (userId, userData)  => dispatch => {
  dispatch(setUserLoading());
  axios 
    .post(`/api/admin/user/${userId}`, userData)
    .then(res =>
      dispatch({
        type: UPDATE_USER,
        payload: res.data
      })
    )
    .catch(err => 
      dispatch({
        type: GET_USER,
        payload: null
      })
    );
};

// Set Loading state
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};