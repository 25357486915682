import React from 'react';
import { Link } from 'react-router-dom';

const CommService = () => {
  return (
    <div className="comm-service">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Link to="/" className="btn btn-light my-2">
              Go Back
            </Link>
            <h1 className="display-3 text-center mb-3">Community Service</h1>
            <blockquote className="blockquote text-center">
              <p className="mb-0 font-italic">
                You can't help everybody, but you can help somebody.
              </p>
            </blockquote>
            <p className="lead">
              This is the principle that motivates the Coaching Legends to
              continue to have an impact and contribute to our community.
            </p>
            <div className="card my-3">
              <h5 className="card-header">2011 THANKSGIVING HOLIDAY</h5>
              <div className="card-body">
                <h5 className="card-title">Turkey Giveaway</h5>
                <p className="card-text">
                  Coaches donated $500 for turkey dinners to the{' '}
                  <a
                    href="http://www.metromin.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Metropolitan Ministries
                  </a>
                  , a faith based organization providing for poor and homeless
                  families living in our community. Their services help
                  alleviate suffering, promote dignity, and instill
                  self-sufficiency. The Manager of Community Donations, who is
                  the daughter of a former high school football coach, sent a
                  thank you letter to the coaches.
                </p>
                <a
                  href={require('../../img/commservice/2011_metro_ministries.jpg')}
                  className="btn btn-primary"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">2011 Christmas Season</h5>
              <div className="card-body">
                <h5 className="card-title">Clothing Giveaway</h5>
                <p className="card-text">
                  Donated $500 for much needed new clothing to the infants and
                  teenagers at the{' '}
                  <a
                    href="http://www.metromin.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Metropolitan Ministries
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">MARCH 2012</h5>
              <div className="card-body">
                <h5 className="card-title">Gift Baskets Giveaway</h5>
                <p className="card-text">
                  Donated 75 gift baskets to{' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.floridahospital.com/tampa"
                  >
                    Florida Hospital of Tampa
                  </a>{' '}
                  (formally University Community Hospital). These baskets
                  contribute to the healing mission of the hospital and help
                  put a smile on a childs face. The staff at the Pediatric
                  Care Center sent a thank you letter. Also, an 8 year old
                  patient and his mom sent a thank you letter to the coaches
                  for their generosity and thoughtfulness.
                </p>
                <a
                  href={require('../../img/commservice/2012_mar_fl_hosp.jpg')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from Staff
                </a>
                <a
                  href={require('../../img/commservice/2012_ped_care.gif')}
                  className="btn btn-primary"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from Patience
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">2012 THANKSGIVING HOLIDAY</h5>
              <div className="card-body">
                <h5 className="card-title">Turkey Giveaway</h5>
                <p className="card-text">
                  Donated $500 for turkey dinners to the{' '}
                  <a
                    href="http://www.metromin.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Metropolitan Ministries
                  </a>
                  . The Manager of Community Donations sent a thank you
                  letter.
                </p>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">2012 Christmas Season</h5>
              <div className="card-body">
                <h5 className="card-title">Clothing Giveaway</h5>
                <p className="card-text">
                  Donated $500 for much needed new clothing to the infants and
                  teenagers at the{' '}
                  <a
                    href="http://www.metromin.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Metropolitan Ministries
                  </a>
                  .
                </p>
              </div>
              <hr />
              <div className="card-body">
                <h5 className="card-title">School Donation</h5>
                <p className="card-text">
                  Seventy-five warm sweatshirts were donated to{' '}
                  <a
                    href="http://miles.mysdhc.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Miles Elementary School
                  </a>
                  .The principal of the school sent a letter to the coaches
                  thanking them for their generosity.
                </p>
                <a
                  href={require('../../img/commservice/2012_nov_miles.jpg')}
                  className="btn btn-primary"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">FEBRUARY 2013</h5>
              <div className="card-body">
                <h5 className="card-title">Gift Baskets Giveaway</h5>
                <p className="card-text">
                  Donated 105 gift baskets to{' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.floridahospital.com/tampa"
                  >
                    Florida Hospital of Tampa
                  </a>
                  . The staff sent a thank you letter. Also, a letter was sent
                  explaining how a 3 year old patient was made happy and
                  playful when she engaged with all the toys in the basket.
                </p>
                <a
                  href={require('../../img/commservice/2013_jan_ped_fl_hosp.jpg')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from Staff
                </a>
                <a
                  href={require('../../img/commservice/2013_feb_fl_hosp.jpg')}
                  className="btn btn-primary"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from Patience
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">MARCH 2013</h5>
              <div className="card-body">
                <h5 className="card-title">CLothing Giveaway</h5>
                <p className="card-text">
                  Donated socks and underwear valued at $300 to the students
                  at{' '}
                  <a href="http://mort.mysdhc.org/">Mort Elementary School</a>
                  . The principal of the school sent a thank you letter for
                  the overwhelming thoughtfulness of the coaches.
                </p>
                <a
                  href={require('../../img/commservice/2013_mar_mort.jpg')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from Staff
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">August 2013</h5>
              <div className="card-body">
                <h5 className="card-title">CLothing Giveaway</h5>
                <p className="card-text">
                  Donated socks and underwear valued at $300 to the students
                  at{' '}
                  <a href="http://miles.mysdhc.org/">
                    Miles Elementary School
                  </a>
                  . The principal of the school was incredibly happy and sent
                  a thank you letter to the coaches..
                </p>
                <a
                  href={require('../../img/commservice/2013_aug_miles.pdf')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from Staff
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">NOVEMBER 2013</h5>
              <div className="card-body">
                <h5 className="card-title">CLothing Giveaway</h5>
                <p className="card-text">
                  Donated $500 to{' '}
                  <a
                    href="http://www.metromin.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Metropolitan Ministries
                  </a>{' '}
                  for new clothing items. The Manager of Community Donations
                  sent a thank you letter to the Coaching Legends.
                </p>
                <a
                  href={require('../../img/commservice/2013_nov_met_min.jpg')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from Staff
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">DECEMBER 2013</h5>
              <div className="card-body">
                <h5 className="card-title">School Donation</h5>
                <p className="card-text">
                  Donated sixty-five tracksuits and sweatshirts to the kids at{' '}
                  <a href="http://mort.mysdhc.org/">
                    Paul Mort Elementary School
                  </a>
                  . The Principal and School Social Worker sent a letter
                  thanking the Coaches for sharing their spirit of giving with
                  at risk children.
                </p>
                <a
                  href={require('../../img/commservice/2013_dec_mort.jpg')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from Staff
                </a>
              </div>
              <hr />
              <div className="card-body">
                <h5 className="card-title">School Donation</h5>
                <p className="card-text">
                  Donated sixty jackets to the students at{' '}
                  <a
                    href="http://miles.mysdhc.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Miles Elementary School
                  </a>
                  . The Principal on behalf of the students, parents, faculty
                  and staff sent a letter thanking the Coaches for their
                  generous contribution.
                </p>
                <a
                  href={require('../../img/commservice/2013_dec_miles.jpg')}
                  className="btn btn-primary"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">January 2014</h5>
              <div className="card-body">
                <h5 className="card-title">Toy Donations</h5>
                <p className="card-text">
                  Donated 105 toy-filled buckets to the Pediatric Care Center
                  at{' '}
                  <a href="https://www.floridahospital.com/tampa">
                    Florida Hospital Tampa
                  </a>
                  . The staff, patients and families sent a thank you letter
                  for the generosity and thoughtfulness of the Coaching
                  Legends.
                </p>
                <a
                  href={require('../../img/commservice/2014_jan_fl_hosp.jpg')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from Staff
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">AUGUST 2014</h5>
              <div className="card-body">
                <h5 className="card-title">Cloths Donations</h5>
                <p className="card-text">
                  Donated socks and underwear to{' '}
                  <a
                    href="http://miles.mysdhc.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Miles Elementary School
                  </a>
                  . The Principal sent a thank you letter to the Coaching
                  Legends for assisting students who are in need of basic
                  supplies.
                </p>
                <a
                  href={require('../../img/commservice/2014_aug_miles.jpg')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from Principal
                </a>
              </div>
              <hr />
              <div className="card-body">
                <h5 className="card-title">Cloths Donations</h5>
                <p className="card-text">
                  Donated socks, underwear and cups to{' '}
                  <a href="http://mort.mysdhc.org/">
                    Paul Mort Elementary School
                  </a>
                  . The Principal and School Social Worker sent a thank you
                  letter for sharing the spirit of giving to children in need.
                </p>
                <a
                  href={require('../../img/commservice/2014_aug_mort.jpg')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from School
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">DECEMBER 2014</h5>
              <div className="card-body">
                <h5 className="card-title">Cloths Donations</h5>
                <p className="card-text">
                  Donated socks, underwear and cups to{' '}
                  <a
                    href="http://miles.mysdhc.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Miles Elementary School
                  </a>
                  . The principal sent an appreciation letter for the
                  continuing support of the coaches.
                </p>
                <a
                  href={require('../../img/commservice/2014_dec_miles.jpg')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from School
                </a>
              </div>
              <hr />
              <div className="card-body">
                <h5 className="card-title">Cloths Donations</h5>
                <p className="card-text">
                  Donated 81 sweat suits to{' '}
                  <a href="http://mort.mysdhc.org/">
                    Paul Mort Elementary School
                  </a>
                  . The social worker sent a grateful letter to the coaches
                  for their contribution and partnership.
                </p>
                <a
                  href={require('../../img/commservice/2014_dec_mort.jpg')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from School
                </a>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header">AUGUST 2015</h5>
              <div className="card-body">
                <h5 className="card-title">Cloths Donations</h5>
                <p className="card-text">
                  Donated socks and undergarments to{' '}
                  <a
                    href="http://miles.mysdhc.org/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Miles Elementary School
                  </a>
                  . On behalf of the students, parents, faculty and staff, the
                  principal sent a letter thanking the coaches for their
                  continuing support.
                </p>
                <a
                  href={require('../../img/commservice/2015_aug_miles.pdf')}
                  className="btn btn-primary mr-2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Thank You Letter from School
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommService;
