import {
  ADD_USER,
  UPDATE_USER,
  GET_USERS,
  GET_USER,
  USER_LOADING,
  DELETE_USER
} from '../actions/types';

const initalState = {
  users: [],
  user: {},
  loading: false
};

export default function(state = initalState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        loading: true
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case ADD_USER: 
      return {
        ...state,
        users: [action.payload, ...state.users]
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter(user => user._id !== action.payload)
      };
    default: 
      return state;
  }
}