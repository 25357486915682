import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {deleteYouTubeVideo} from '../../actions/profileActions';

class YouTube extends Component {
  onDelectClick = id => {
    this.props.deleteYouTubeVideo(id);
  }
  render() {
    const youtubevideos = this.props.youtubevideos.map(ytVideo => (
      <div className="p-2" key={ytVideo._id}>
        <div className="row">
          <div className="col-md-7">
            <button type="button" data-toggle="modal" data-target={`#youTubeModal${ytVideo._id}`}>
              <img
                src={ytVideo.url}
                alt={ytVideo.url}
                style={{
                  width: '375px',
                  height: '250px'
                }}
                className="img-thumbnail"
              />
            </button>
          </div>
          <div className="col-md-5">
            <p className="text-info">{ytVideo.title}</p>
            <p>{ytVideo.description}</p>
            <button
              onClick={() => this.onDelectClick(ytVideo._id)}
              className="btn btn-danger"
            >
              Remove Video
            </button>
          </div>
        </div>
      
      <div className="modal fade" id={`youTubeModal${ytVideo._id}`} tabIndex="-1" role="dialog" aria-labelledby={`youTubeModal${ytVideo._id}Title`} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-body">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      title="Video Player"
                      className="embed-responsive-item"
                      width="auto"
                      height="auto"
                      src={`https://www.youtube.com/embed/${ytVideo.videoID}`}
                      allowFullScreen
                      />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <hr />
      </div>
    ));
    return (
      <>
        <div className="card bg-transparent border-0">
        <h4 className="mb-4">YouTube Videos</h4>
          <div className="card-body">
            {youtubevideos.length > 0 ? (
              <span>{youtubevideos}</span>
            ) : (
              <p className="text-center">No Videos Saved</p>
            )}
          </div>
        </div>
        
      </>
    );
  }
}

YouTube.propTypes = {
  deleteYouTubeVideo: PropTypes.func.isRequired
}

export default connect(
  null, 
  {deleteYouTubeVideo}
  )(YouTube);
