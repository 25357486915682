import React from 'react';
import { Link } from 'react-router-dom';

const ProfileActions = () => {
  return (
    <div className="row">
      <div className="col-xs-6 mx-auto">
        <div className="btn-group-sm btn-group-vertical mb-2" role="group">
          <Link to="/upload-profile-pic" className="btn btn-light">
            <i className="fas fa-user-circle text-info mr-1" />
            Change Pic
          </Link>
          <Link to="/edit-profile" className="btn btn-light">
            <i className="fas fa-user-circle text-info mr-1" />
            Edit Profile
          </Link>
          <Link to="/add-experience" className="btn btn-light">
            <i className="fas fa-football-ball text-info mr-1" />
            Add Experience
          </Link>
          <Link to="/add-education" className="btn btn-light">
            <i className="fas fa-graduation-cap text-info mr-1" />
            Add Education
          </Link>
        </div>
      </div>
      <div className="col-xs-6 mx-auto">
        <div className="btn-group-sm btn-group-vertical mb-2" role="group">
          <Link to="/" className="btn btn-light">
            <i className="far fa-images text-info mr-1" />
            Not Used Yet
          </Link>
          <Link to="/search-youtube" className="btn btn-light">
            <i className="fab fa-youtube-square text-info mr-1" />
            Search/Share Videos
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileActions;
