import React, { Component } from 'react';
import CalEventForm from '../calEvents/CalEventForm';

class ManagerCreateEvent extends Component {
  render() {
    return (
      <div>
        <h1 className="text-center">Create Event</h1>
        <CalEventForm />
      </div>
    )
  }
}

export default ManagerCreateEvent;
