import React from 'react';
import { Link } from 'react-router-dom';
import Photos from '../photos/Photos';

const ArchivedPhotos = () => {
  return (
    <div className="archived-photos">
      <div className="container">
        <Link to="/" className="mt-2 btn btn-light">
          Go Back
        </Link>
        <hr />
        <h1 className="display-4 mb-2">Photos</h1>
        <div className="row text-center">
          <Photos  />
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/bowman.jpg')}
                alt="Pat Healey, Bobby Bowman"
              />
              <div className="card-body">
                <p className="card-text">Pat Healey, Bobby Bowman</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/acosta_ziegler.jpg')}
                alt="Dan Acosta, Bob Ziegler"
              />
              <div className="card-body">
                <p className="card-text">Dan Acosta, Bob Ziegler</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/adams_cagnia_fowler.jpg')}
                alt="Bob Adams, Ron Cagnia, Adrian Fowler"
              />
              <div className="card-body">
                <p className="card-text">Bob Adams, Ron Cagnia, Adrian Fowler</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/aplin_hicks.jpg')}
                alt="Doug Aplin, John Hicks"
              />
              <div className="card-body">
                <p className="card-text">Doug Aplin, John Hicks</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/asbell_macaluso_permuy_larkin_granda.gif')}
                alt="Dick Asbell, Jim Macaluso, Frank Permuy, Williw Larkin, Wayne Granda"
              />
              <div className="card-body">
                <p className="card-text">Dick Asbell, Jim Macaluso, Frank Permuy, Williw Larkin, Wayne Granda</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/baker_soash.jpg')}
                alt="Mike Baker, Norm Soash"
              />
              <div className="card-body">
                <p className="card-text">Mike Baker, Norm Soash</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/barreda_barreda.jpg')}
                alt="Jose Barrreda, Jashua Barreda"
              />
              <div className="card-body">
                <p className="card-text">Jose Barrreda, Jashua Barreda</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/bennett_hicks_rodriguez.jpg')}
                alt="Jan Bennett, John Hicks, Sam Rodriguez"
              />
              <div className="card-body">
                <p className="card-text">Jan Bennett, John Hicks, Sam Rodriguez</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/bennett_lombardia.gif')}
                alt="Jan Bennett, K.R. Lombardia"
              />
              <div className="card-body">
                <p className="card-text">Jan Bennett, K.R. Lombardia</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/bennett_mosca.gif')}
                alt="Jan Bennett, Tom Mosca"
              />
              <div className="card-body">
                <p className="card-text">Jan Bennett, Tom Mosca</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/bonilla_bowers.jpg')}
                alt="Dan Bonilla, Lou Bowers"
              />
              <div className="card-body">
                <p className="card-text">Dan Bonilla, Lou Bowers</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/bonilla_oliver_reid.jpg')}
                alt="Dan Bonilla, Frank Oliver, Joe Reid"
              />
              <div className="card-body">
                <p className="card-text">Dan Bonilla, Frank Oliver, Joe Reid</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/bowman_donaldson.jpg')}
                alt="Bobby Bowman, Dennis Donaldson"
              />
              <div className="card-body">
                <p className="card-text">Bobby Bowman, Dennis Donaldson</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/bowman_patton.png')}
                alt="Bobby Bowman, Joe Patton"
              />
              <div className="card-body">
                <p className="card-text">Bobby Bowman, Joe Patton</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/bowman.jpg')}
                alt="Pat Healey, Bobby Bowman"
              />
              <div className="card-body">
                <p className="card-text">Pat Healey, Bobby Bowman</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/brimm.jpg')}
                alt="Willard Brimm"
              />
              <div className="card-body">
                <p className="card-text">Willard Brimm</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/brooks_durnin_ross.gif')}
                alt="Clem Brooks, Jerry Durnin, Sanford Ross"
              />
              <div className="card-body">
                <p className="card-text">Clem Brooks, Jerry Durnin, Sanford Ross</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/burns_copeland_durnin.gif')}
                alt="Jack Burns, Jim Copeland, Jerry Durnin"
              />
              <div className="card-body">
                <p className="card-text">Jack Burns, Jim Copeland, Jerry Durnin</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/burns.jpg')}
                alt="Jack Burns honors Jerry Durnin with memories of the past"
              />
              <div className="card-body">
                <p className="card-text">Jack Burns honors Jerry Durnin with memories of the past</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/burns2.jpg')}
                alt="Jack Burns to present 'A True Legend' plaque to Jerry Durnin's family"
              />
              <div className="card-body">
                <p className="card-text">Jack Burns to present "A True Legend" plaque to Jerry Durnin's family</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/copeland_skora.gif')}
                alt="Jim Copeland, Jerry Skora"
              />
              <div className="card-body">
                <p className="card-text">Jim Copeland, Jerry Skora</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/craig_mulry_berreda.jpg')}
                alt="George Craig, Pete Mulry Jose Barreda"
              />
              <div className="card-body">
                <p className="card-text">George Craig, Pete Mulry Jose Barreda</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/cuesta_granda.jpg')}
                alt="Pop Cuesta, Rudy Granda"
              />
              <div className="card-body">
                <p className="card-text">Pop Cuesta, Rudy Granda</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/cuesta_alfonso.gif')}
                alt="Pop Cuesta, Joe Alfonso"
              />
              <div className="card-body">
                <p className="card-text">Pop Cuesta, Joe Alfonso</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/degenoba_hallauer_menegus_pusins_cuesta.jpg')}
                alt="Joe DeGenoba, Gary Hallauer, Tom Menegus, Dick Pusins, Pop Cuesta"
              />
              <div className="card-body">
                <p className="card-text">Joe DeGenoba, Gary Hallauer, Tom Menegus, Dick Pusins, Pop Cuesta</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/degenoba_riviero_cuesta.jpg')}
                alt="Joe DeGenoba , Dan Riviero, Pop Cuesta"
              />
              <div className="card-body">
                <p className="card-text">Joe DeGenoba , Dan Riviero, Pop Cuesta</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/delama_staley_lentz.jpg')}
                alt="Richard de Lama, Leroy Staley, Frank Lentz"
              />
              <div className="card-body">
                <p className="card-text">Richard de Lama, Leroy Staley, Frank Lentz</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/diez_gonzalez.jpg')}
                alt="Bob Diez, Oscar Gonzalez"
              />
              <div className="card-body">
                <p className="card-text">Bob Diez, Oscar Gonzalez</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/dixon_sweeney.jpg')}
                alt="Hank Dixon, Ken Sweeney"
              />
              <div className="card-body">
                <p className="card-text">Hank Dixon, Ken Sweeney</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/dunn_adams.jpg')}
                alt="Jimmy Dunn, Bob Adams"
              />
              <div className="card-body">
                <p className="card-text">Jimmy Dunn, Bob Adams</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/dyer_bowman_bennett_small_gibson_paula_pusins_gibbons_valdez_lentz_mosca.jpg')}
                alt="Basketball Coaches; Richard Dyer, Bobby Bowman, Jan Bennett, Dave Small Dave Gibson, Marcus Paula, Dick Pusins, Gordon Gibbons Herman Valdez, Frank Lentz, Tom Mosca"
              />
              <div className="card-body">
                <p className="card-text">Basketball Coaches; Richard Dyer, Bobby Bowman, Jan Bennett, Dave Small Dave Gibson, Marcus Paula, Dick Pusins, Gordon Gibbons Herman Valdez, Frank Lentz, Tom Mosca</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/dyer_delama_pinholster.jpg')}
                alt="Richard Dyer, Richard de Lama, Chris Pinholser"
              />
              <div className="card-body">
                <p className="card-text">Richard Dyer, Richard de Lama, Chris Pinholser</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/dyer_drake.png')}
                alt="Richard Dyer, Jim Drake"
              />
              <div className="card-body">
                <p className="card-text">Richard Dyer, Jim Drake</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/dyer_lentz.jpg')}
                alt="Richard Dyer, Frank Lentz"
              />
              <div className="card-body">
                <p className="card-text">Richard Dyer, Frank Lentz</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/dyer_otero.jpg')}
                alt="Richard Dyer, Ken Otero"
              />
              <div className="card-body">
                <p className="card-text">Richard Dyer, Ken Otero</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/dyer_pusins.jpg')}
                alt="Richard Dyer, Courtney, Dick Pusins"
              />
              <div className="card-body">
                <p className="card-text">Richard Dyer, Courtney, Dick Pusins</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/easley_dyer.jpg')}
                alt="Keith Easley, Richard Dyer"
              />
              <div className="card-body">
                <p className="card-text">Keith Easley, Richard Dyer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/fernandez_kolinsky_riviero.jpg')}
                alt="Frank Fernandez, Joe Kolinsky, Dan Riviero"
              />
              <div className="card-body">
                <p className="card-text">Frank Fernandez, Joe Kolinsky, Dan Riviero</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/fernandez_adams-gore.jpg')}
                alt="Frank Fernandez, Bob Adams, Paul Gore"
              />
              <div className="card-body">
                <p className="card-text">Frank Fernandez, Bob Adams, Paul Gore</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/figueredo_toward.jpg')}
                alt="Joe Figueredo, David Toward"
              />
              <div className="card-body">
                <p className="card-text">Joe Figueredo, David Toward</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/fisher_dunn.jpg')}
                alt="Jimmy Fisher, Jimmy Dunn"
              />
              <div className="card-body">
                <p className="card-text">Jimmy Fisher, Jimmy Dunn</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/fowler_dyer.jpg')}
                alt="Wayne Fowler, Richard Dyer"
              />
              <div className="card-body">
                <p className="card-text">Wayne Fowler, Richard Dyer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/fyfe_asbell_macaluso_brooks_reed_gonzalez_larkin_granda_permuy.gif')}
                alt="Dick Asbell, Jim Macaluso, Frank Permuy, Willie Larkin, Wayne Granda"
              />
              <div className="card-body">
                <p className="card-text">Dick Asbell, Jim Macaluso, Frank Permuy, Willie Larkin, Wayne Granda</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/fyfe_kollmeyer.jpg')}
                alt="Davis Fyfr, Denny Kolimeyer"
              />
              <div className="card-body">
                <p className="card-text">Davis Fyfr, Denny Kolimeyer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/garcia_turner_pinholster_sweeney_durig.png')}
                alt="Lou Garcia, Billy Turner, Chris Pinholster, Ken Sweeney, Mike Durig"
              />
              <div className="card-body">
                <p className="card-text">Lou Garcia, Billy Turner, Chris Pinholster, Ken Sweeney, Mike Durig</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/gebauer_english_mcbride.jpg')}
                alt="Billie Gerbauer, Jerry English, Charles McBride"
              />
              <div className="card-body">
                <p className="card-text">Billie Gerbauer, Jerry English, Charles McBride</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/gebauer_rairigh.jpg')}
                alt="Billie Gebauer, Ray Rairigh"
              />
              <div className="card-body">
                <p className="card-text">Billie Gebauer, Ray Rairigh</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/gebauer_weiss.gif')}
                alt="Billie Gerbauer, Ken Weiss"
              />
              <div className="card-body">
                <p className="card-text">Billie Gerbauer, Ken Weiss</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/gerbauer_fernandez_pollock_kennedy_dimaggio.gif')}
                alt="Bill Gerbauer, Frank Fernandez, Georgee Pollock, Mike Kennedy, Nick DiMaggio"
              />
              <div className="card-body">
                <p className="card-text">Bill Gerbauer, Frank Fernandez, Georgee Pollock, Mike Kennedy, Nick DiMaggio</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/gibson_kocher.jpg')}
                alt="Dave Gibson, Marcy Scholl, Tim Kocher"
              />
              <div className="card-body">
                <p className="card-text">Dave Gibson, Marcy Scholl, Tim Kocher</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/gibson_weiss.jpg')}
                alt="Dave Gibson, Jim Marshall. Ken Weiss"
              />
              <div className="card-body">
                <p className="card-text">Dave Gibson, Jim Marshall. Ken Weiss</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/gonzalez_nelson.jpg')}
                alt="Oscar Gonzalez, Lynwwood Nelson"
              />
              <div className="card-body">
                <p className="card-text">Oscar Gonzalez, Lynwwood Nelson</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/grantham_phillips_grantham.jpg')}
                alt="Brian Grantham, Mike Phillips, David Grantham"
              />
              <div className="card-body">
                <p className="card-text">Brian Grantham, Mike Phillips, David Grantham</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/hallauer_riviero_gibson.jpg')}
                alt="Gary Hallauer, Dan Riviero, Dave Gibson"
              />
              <div className="card-body">
                <p className="card-text">Gary Hallauer, Dan Riviero, Dave Gibson</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/haller_solmonte.gif')}
                alt="Gary Hallauer, Vince Solmonte"
              />
              <div className="card-body">
                <p className="card-text">Gary Hallauer, Vince Solmonte</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/hernandez_barreda.jpg')}
                alt="Robin Hernandez, Jose Barred"
              />
              <div className="card-body">
                <p className="card-text">Robin Hernandez, Jose Barred</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/hicks_tripolino_bennett.jpg')}
                alt="John Hicks, Anthony Tripolino, Jan Bennett"
              />
              <div className="card-body">
                <p className="card-text">John Hicks, Anthony Tripolino, Jan Bennett</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/hodgens_fyfe_granda_riviero_lombardia_balanca.jpg')}
                alt="Bud Hodgens, David Fyfe, Wayne Granda, Dan Riviero, K.R. Lombardia, Tony Balanca"
              />
              <div className="card-body">
                <p className="card-text">Bud Hodgens, David Fyfe, Wayne Granda, Dan Riviero, K.R. Lombardia, Tony Balanca</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/hodgens_soash_sweeney.gif')}
                alt="Bud Hodgens, Norm Soash, Ken Sweeney"
              />
              <div className="card-body">
                <p className="card-text">Bud Hodgens, Norm Soash, Ken Sweeney</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/hodgens_sweeney_thomas.jpg')}
                alt="Bud Hodgens, Ken Sweeney, Bob Morgan, Mark Shelton, Randy Thomas"
              />
              <div className="card-body">
                <p className="card-text">Bud Hodgens, Ken Sweeney, Bob Morgan, Mark Shelton, Randy Thomas</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/hodgens_valdez_staley.jpg')}
                alt="Bud Hodges, Herman Valdez, Leroy Staley"
              />
              <div className="card-body">
                <p className="card-text">Bud Hodges, Herman Valdez, Leroy Staley</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/jackson_pascual_dyer.gif')}
                alt="Ron Jackson, Bobby Pascual, Richard Dyer"
              />
              <div className="card-body">
                <p className="card-text">Ron Jackson, Bobby Pascual, Richard Dyer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/kayian_fontanills_severino.jpg')}
                alt="Harry Kayian, Henry Fontanills jr, Joe Severino"
              />
              <div className="card-body">
                <p className="card-text">Harry Kayian, Henry Fontanills jr, Joe Severino</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/kennedy_murray_rodriguez_muley_bonfe.jpg')}
                alt="Mile Kennedy, Tom Murray, Sam Rodriguez, Pete Mulry, Greg Bonfe"
              />
              <div className="card-body">
                <p className="card-text">Mile Kennedy, Tom Murray, Sam Rodriguez, Pete Mulry, Greg Bonfe</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/kennedy_solmonte_rothell_patton.jpg')}
                alt="Mike Kennedy, Vince Solmonte, Don Rothell, Joe Patton"
              />
              <div className="card-body">
                <p className="card-text">Mike Kennedy, Vince Solmonte, Don Rothell, Joe Patton</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/kollmeyer_reed.gif')}
                alt="Denny Kolimeyer, Billy Reed"
              />
              <div className="card-body">
                <p className="card-text">Denny Kolimeyer, Billy Reed</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/korhn_kolinsky_solmonte.jpg')}
                alt="Veron Korhn, Joe Kolinsky, Vince Solmonte"
              />
              <div className="card-body">
                <p className="card-text">Veron Korhn, Joe Kolinsky, Vince Solmonte</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/korhn.jpg')}
                alt="Vernon Korhn"
              />
              <div className="card-body">
                <p className="card-text">Vernon Korhn</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/kranich.jpg')}
                alt="Bill Kranich - Owner of Brunchies"
              />
              <div className="card-body">
                <p className="card-text">Bill Kranich - Owner of Brunchies</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/kuharchek_vining_mosca.jpg')}
                alt="Pete Kuharcheck, Frank Vining, Tom Mosca"
              />
              <div className="card-body">
                <p className="card-text">Pete Kuharcheck, Frank Vining, Tom Mosca</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/larkin_arbelljpg.jpg')}
                alt="Willie Larkin, Dick Arbell"
              />
              <div className="card-body">
                <p className="card-text">Willie Larkin, Dick Arbell</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/larson_johnson.png')}
                alt="Mike Larson, Russell Johnson"
              />
              <div className="card-body">
                <p className="card-text">Mike Larson, Russell Johnson</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/lentz_bowman_tinnin.jpg')}
                alt="Frank Lentz, Bobby Bowman, Jean Tinnin"
              />
              <div className="card-body">
                <p className="card-text">Frank Lentz, Bobby Bowman, Jean Tinnin</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/lentz_harrell.jpg')}
                alt="Frank Lentz, James Harrell"
              />
              <div className="card-body">
                <p className="card-text">Frank Lentz, James Harrell</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/lentz_howell.gif')}
                alt="Frank Lentz, Billy Howell"
              />
              <div className="card-body">
                <p className="card-text">Frank Lentz, Billy Howell</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/lentz_long.jpg')}
                alt="Frank Fernandez, Bob Adams, Paul Gore"
              />
              <div className="card-body">
                <p className="card-text">Frank Fernandez, Bob Adams, Paul Gore</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/lomardia_pernuy.jpg')}
                alt="KR Lombardia, Frank Permuy"
              />
              <div className="card-body">
                <p className="card-text">KR Lombardia, Frank Permuy</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/lombardia__erwin_riveiro.jpg')}
                alt="KR Lombardia, Doug Erwin, Dan Riviero"
              />
              <div className="card-body">
                <p className="card-text">KR Lombardia, Doug Erwin, Dan Riviero</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/lombardia_granda_stewart_long_macaluso.jpg')}
                alt="KR Lombardia, Rudy Granda, Derek Stewart, Leroy Long, Jim Macaluso"
              />
              <div className="card-body">
                <p className="card-text">KR Lombardia, Rudy Granda, Derek Stewart, Leroy Long, Jim Macaluso</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/lombardia_permuy_gibbons.jpg')}
                alt="KR lombardia, Frank Permuy, Gordon Gibbons"
              />
              <div className="card-body">
                <p className="card-text">KR lombardia, Frank Permuy, Gordon Gibbons</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/lou_garcia.png')}
                alt="Lou Garcia"
              />
              <div className="card-body">
                <p className="card-text">Lou Garcia</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/macaluso.jpg')}
                alt="Jim Macaluso gives tribute and admiration to Jerry Durnin"
              />
              <div className="card-body">
                <p className="card-text">Jim Macaluso gives tribute and admiration to Jerry Durnin</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/magidan_hicks.jpg')}
                alt="Joe Magadan, John Hicks"
              />
              <div className="card-body">
                <p className="card-text">Joe Magadan, John Hicks</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/marshal_white_reed.jpg')}
                alt="Jim Marshall, Chuck White, Billy Reed"
              />
              <div className="card-body">
                <p className="card-text">Jim Marshall, Chuck White, Billy Reed</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/marshall_macaluso_thompson.jpg')}
                alt="Jim Marshall, Jim Macaluso, Jack Thompson"
              />
              <div className="card-body">
                <p className="card-text">Jim Marshall, Jim Macaluso, Jack Thompson</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/mcbride_delama_thomas_sweeney.jpg')}
                alt="Charlie McBride, Richard de Lama, Randy Thomas, Ken Sweeney"
              />
              <div className="card-body">
                <p className="card-text">Charlie McBride, Richard de Lama, Randy Thomas, Ken Sweeney</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/mcdowell.jpg')}
                alt="Cliff McDowell"
              />
              <div className="card-body">
                <p className="card-text">Cliff McDowell</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/mclaughlin_riviero_pinholster_rodriguez.jpg')}
                alt="Lane McLaughlin, Dan Riviero, Chris Pinholster, Sam Rodriguez"
              />
              <div className="card-body">
                <p className="card-text">Lane McLaughlin, Dan Riviero, Chris Pinholster, Sam Rodriguez</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/mills_schmidt_gerbauer_weiss.gif')}
                alt="Jack Mills, Pete Schmidt, Billie Gerbauer, Ken Weiss"
              />
              <div className="card-body">
                <p className="card-text">Jack Mills, Pete Schmidt, Billie Gerbauer, Ken Weiss</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/minahan_pascual_lentz.gif')}
                alt="Bill Minaham, Bobby Pascual, Frank Lentz"
              />
              <div className="card-body">
                <p className="card-text">Bill Minaham, Bobby Pascual, Frank Lentz</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/minihan_hicks_weiss_turner_minihan_marshall.jpg')}
                alt="MArtha Minahan, John Hicks, Ken Weiss, Billy Turner, Bill Minahan, Jim Marshall"
              />
              <div className="card-body">
                <p className="card-text">MArtha Minahan, John Hicks, Ken Weiss, Billy Turner, Bill Minahan, Jim Marshall</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/morgan_bonilla.jpg')}
                alt="Bob Morgan, Dan Bonilla"
              />
              <div className="card-body">
                <p className="card-text">Bob Morgan, Dan Bonilla</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/mosca_burns_durnin_hodgens.gif')}
                alt="Tom Mosca, Jack Burns, Jerry Durnin, Bud Hodgens"
              />
              <div className="card-body">
                <p className="card-text">Tom Mosca, Jack Burns, Jerry Durnin, Bud Hodgens</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/mosca_valdez_unknown.jpg')}
                alt="Tom Mosca, Herman Valdez, Scott Burket"
              />
              <div className="card-body">
                <p className="card-text">Tom Mosca, Herman Valdez, Scott Burket</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/muley_turner_fernandez_marshall.jpg')}
                alt="Pete Muley, Billy Turner, Frank Fernandez, Jim Marshall"
              />
              <div className="card-body">
                <p className="card-text">Pete Muley, Billy Turner, Frank Fernandez, Jim Marshall</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/nelson.gif')}
                alt="Darlee Nelson"
              />
              <div className="card-body">
                <p className="card-text">Darlee Nelson</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/nelson_dyer.jpg')}
                alt="Bruce Nelson, Richard Dyer"
              />
              <div className="card-body">
                <p className="card-text">Bruce Nelson, Richard Dyer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/nelson_gore.gif')}
                alt="Darlee Nelson, Paul Gore"
              />
              <div className="card-body">
                <p className="card-text">Darlee Nelson, Paul Gore</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/parlett_toward.jpg')}
                alt="Ron Parlett, David Toward"
              />
              <div className="card-body">
                <p className="card-text">Ron Parlett, David Toward</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/patton_stokes.jpg')}
                alt="Joe Patton, Taylor Stokes"
              />
              <div className="card-body">
                <p className="card-text">Joe Patton, Taylor Stokes</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/patton_williamson.jpg')}
                alt="Joe Patton, Wayne Williamson"
              />
              <div className="card-body">
                <p className="card-text">Joe Patton, Wayne Williamson</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/paula_dyer.jpg')}
                alt="Marcus Paula, Richard Dyer"
              />
              <div className="card-body">
                <p className="card-text">Marcus Paula, Richard Dyer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/perez_diez_balanca_cuesta_morgan_schmidt.jpg')}
                alt="Ray Perez, Bob Dies, Tony Ballanca, Pop Cuesta, Bob Morgan, Pete Schmidt"
              />
              <div className="card-body">
                <p className="card-text">Ray Perez, Bob Dies, Tony Ballanca, Pop Cuesta, Bob Morgan, Pete Schmidt</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/perez_owen_hallauer.jpg')}
                alt="Frank Perez, Ed Owen, Gary Hallauer"
              />
              <div className="card-body">
                <p className="card-text">Frank Perez, Ed Owen, Gary Hallauer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/permuy_gonzalez.gif')}
                alt="Frank Permuy, Oscar Gonzalez"
              />
              <div className="card-body">
                <p className="card-text">Frank Permuy, Oscar Gonzalez</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/peterson_depue.jpg')}
                alt="Pete Peterson, Mike DuPue"
              />
              <div className="card-body">
                <p className="card-text">Pete Peterson, Mike DuPue</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/pfunda_small_toward.jpg')}
                alt="Jeff Pfunda, Dave Small, David Toward"
              />
              <div className="card-body">
                <p className="card-text">Jeff Pfunda, Dave Small, David Toward</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/pinholster_fenton_kr_dan.jpg')}
                alt="Chris Pinholster, Mike Fenton, KR Lombardia, Dan Cosby"
              />
              <div className="card-body">
                <p className="card-text">Chris Pinholster, Mike Fenton, KR Lombardia, Dan Cosby</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/poff_murray_bonfe.png')}
                alt="Walt Poff, Tom Murray, Greg Bonfe"
              />
              <div className="card-body">
                <p className="card-text">Walt Poff, Tom Murray, Greg Bonfe</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/pulaski.jpg')}
                alt="Loren Pulaski; Server at Brunchies"
              />
              <div className="card-body">
                <p className="card-text">Loren Pulaski server at Brunchies</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/pusins_bonfe_delama.gif')}
                alt="Dick Pusins, Greg Bonfe, Richard de Lama"
              />
              <div className="card-body">
                <p className="card-text">Dick Pusins, Greg Bonfe, Richard de Lama</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/pusins_delama_tonelli.gif')}
                alt="Dick Pusins, Richard de Lama, Tommy Tonnelli"
              />
              <div className="card-body">
                <p className="card-text">Dick Pusins, Richard de Lama, Tommy Tonnelli</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/pusins_fyfe_solmonte.jpg')}
                alt="Dick Pusins, Davisd Fyfe, Vince Solmonte"
              />
              <div className="card-body">
                <p className="card-text">Dick Pusins, Davisd Fyfe, Vince Solmonte</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/pusins_fyfe.gif')}
                alt="Dick Pusins, David Fyfe"
              />
              <div className="card-body">
                <p className="card-text">Dick Pusins, David Fyfe</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/pusins_vining_gonzalez.jpg')}
                alt="Dick Pusins, Frank Vining, Oscar Gonzalez"
              />
              <div className="card-body">
                <p className="card-text">Dick Pusins, Frank Vining, Oscar Gonzalez</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/pusins_with_budget.gif')}
                alt="Dick Pusins with Budget Chart"
              />
              <div className="card-body">
                <p className="card-text">Dick Pusins with Budget Chart</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/reisler.jpg')}
                alt="Max Reisler"
              />
              <div className="card-body">
                <p className="card-text">Max Reisler</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/reno_severino.jpg')}
                alt="Frank Reno, Joe Severino"
              />
              <div className="card-body">
                <p className="card-text">Frank Reno, Joe Severino</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/riviero_korhn_kolisnsky.jpg')}
                alt="Dan Riviero, Vernon Korhn, Joe Kolinski"
              />
              <div className="card-body">
                <p className="card-text">Dan Riviero, Vernon Korhn, Joe Kolinski</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/riviero_pusins.jpg')}
                alt="Dan Riviero, Dick Pusins"
              />
              <div className="card-body">
                <p className="card-text">Dan Riviero, Dick Pusins</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/rosen_gonzalez.jpg')}
                alt="Al Rosen, Oscar Gonzalez"
              />
              <div className="card-body">
                <p className="card-text">Al Rosen, Oscar Gonzalez</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/rothell_kennedy.jpg')}
                alt="Don Rothell, Mike Kennedy"
              />
              <div className="card-body">
                <p className="card-text">Don Rothell, Mike Kennedy</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/schmidt_kuharchek.jpg')}
                alt="Pete Schmidt, Pete Kuharcheck"
              />
              <div className="card-body">
                <p className="card-text">Pete Schmidt, Pete Kuharcheck</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/schmidt_reid_erwin.jpg')}
                alt="Pete Schmidt, Joe Reid, Doug Erwin"
              />
              <div className="card-body">
                <p className="card-text">Pete Schmidt, Joe Reid, Doug Erwin</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/severino_martin_soash.jpg')}
                alt="Joe Severino, Tom Martin, Norm Soash"
              />
              <div className="card-body">
                <p className="card-text">Joe Severino, Tom Martin, Norm Soash</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/severino_pinholster_soash_hodgens_kayian.gif')}
                alt="Joe Severino, Chris Pinholster, Norm Soash, Bud Hodgens, Harry Kayian"
              />
              <div className="card-body">
                <p className="card-text">Joe Severino, Chris Pinholster, Norm Soash, Bud Hodgens, Harry Kayian</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/severino_reid.jpg')}
                alt="Joe Dixon, Joe Severino, Joe Reid"
              />
              <div className="card-body">
                <p className="card-text">Joe Dixon, Joe Severino, Joe Reid</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/shelton.jpg')}
                alt="Mark Shelton, Mike DeGenoba"
              />
              <div className="card-body">
                <p className="card-text">Mark Shelton, Mike DeGenoba</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/shelton_toward.jpg')}
                alt="Mark Shelton, David Toward"
              />
              <div className="card-body">
                <p className="card-text">Mark Shelton, David Toward</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/shepher_dyer_solmonte_marzinski_toward.gif')}
                alt="John Shepher, Richard Dyer, Vince Solmonte, Dan Marczinski, David Toward"
              />
              <div className="card-body">
                <p className="card-text">John Shepher, Richard Dyer, Vince Solmonte, Dan Marczinski, David Toward</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/skora_wilson_copeland.gif')}
                alt="Jerry Skora, Bernie Wilson, Jim Copeland"
              />
              <div className="card-body">
                <p className="card-text">Jerry Skora, Bernie Wilson, Jim Copeland</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/small_hicks.jpg')}
                alt="David Small, John Hicks"
              />
              <div className="card-body">
                <p className="card-text">David Small, John Hicks</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/smay_bennett.jpg')}
                alt="Roy Smay, Jan Bennett"
              />
              <div className="card-body">
                <p className="card-text">Roy Smay, Jan Bennett</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/soash_delama__kauffman_mcclellan.jpg')}
                alt="Norm Soash, Richard de Lama, Phil Kauffman, John McClellan"
              />
              <div className="card-body">
                <p className="card-text">Norm Soash, Richard de Lama, Phil Kauffman, John McClellan</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/soash_long_burns.gif')}
                alt="Norm Soash, Leroy Long, Jack Burns"
              />
              <div className="card-body">
                <p className="card-text">Norm Soash, Leroy Long, Jack Burns</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/solmonte_gibson.jpg')}
                alt="Vince Solmonte, Dave Gibson"
              />
              <div className="card-body">
                <p className="card-text">Vince Solmonte, Dave Gibson</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/solmonte_gonzalez_adams.gif')}
                alt="Vince Solmonte, Oscar Gonzalez, Bob Adams"
              />
              <div className="card-body">
                <p className="card-text">Vince Solmonte, Oscar Gonzalez, Bob Adams</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/staley_allen.jpg')}
                alt="Leroy Staley, Herb Allen"
              />
              <div className="card-body">
                <p className="card-text">Leroy Staley, Herb Allen</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/staley_romkey.jpg')}
                alt="Leroy Staley, Jim Romkey"
              />
              <div className="card-body">
                <p className="card-text">Leroy Staley, Jim Romkey</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/sweeney_bell_paula_toward_mar.jpg')}
                alt="Ken Sweeney, Alan Bell, Marcus Paula, David Toward, Dan Marczynski"
              />
              <div className="card-body">
                <p className="card-text">Ken Sweeney, Alan Bell, Marcus Paula, David Toward, Dan Marczynski</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/sweeney_dimaggio_white_dyer_delema.jpg')}
                alt="Ken Sweeney, Nick DiMaggio, Chuck White, Richard Dyer, Richard de Lama"
              />
              <div className="card-body">
                <p className="card-text">Ken Sweeney, Nick DiMaggio, Chuck White, Richard Dyer, Richard de Lama</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/sweeney_wolfe.jpg')}
                alt="Ken Sweeney, Don Wolfe"
              />
              <div className="card-body">
                <p className="card-text">Ken Sweeney, Don Wolfe</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/sweeney.jpg')}
                alt="Ron Pucker, Ken Sweeney"
              />
              <div className="card-body">
                <p className="card-text">Ron Pucker, Ken Sweeney</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/thomas_solmonte_coleman_swinney_fyfe.gif')}
                alt="Randy Thomas, Vince Solmonte, Steve Coleman, Ken Sweeney, David Fyfe"
              />
              <div className="card-body">
                <p className="card-text">Randy Thomas, Vince Solmonte, Steve Coleman, Ken Sweeney, David Fyfe</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/thomas_weiss_gerbauer_lonfellow.jpg')}
                alt="Randy Thomas, Ken Weiss, Bill Gerbauer, Steve Longfellow"
              />
              <div className="card-body">
                <p className="card-text">Randy Thomas, Ken Weiss, Bill Gerbauer, Steve Longfellow</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/thompson_felliciano.jpg')}
                alt="Jack Thompson, John Felliciano"
              />
              <div className="card-body">
                <p className="card-text">Jack Thompson, John Felliciano</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/thompson.jpg')}
                alt="Jack Thompson"
              />
              <div className="card-body">
                <p className="card-text">Jack Thompson</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/tinnin_lambert.jpg')}
                alt="Jean Tinnin, Betty Lambert"
              />
              <div className="card-body">
                <p className="card-text">Jean Tinnin, Betty Lambert</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/tinnin_lentz_delama_sullivan.jpg')}
                alt="Jean Tinnin, Frank Lentz, Richard de Lama, Maureen Sullivan"
              />
              <div className="card-body">
                <p className="card-text">Jean Tinnin, Frank Lentz, Richard de Lama, Maureen Sullivan</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/toward_mosca_morgan.jpg')}
                alt="David Toward, Tom Mosca, Bob Morgan"
              />
              <div className="card-body">
                <p className="card-text">David Toward, Tom Mosca, Bob Morgan</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/toward_scionti.jpg')}
                alt="David Toward, Tony Scionti"
              />
              <div className="card-body">
                <p className="card-text">David Toward, Tony Scionti</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/toward.gif')}
                alt="David Toward"
              />
              <div className="card-body">
                <p className="card-text">David Toward</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/trigg_schmidt_kieffer.jpg')}
                alt="Charles Trigg, Pete Schmidt John Kieffer"
              />
              <div className="card-body">
                <p className="card-text">Charles Trigg, Pete Schmidt John Kieffer</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/turner_kennedy_pusins.jpg')}
                alt="Billy Turner, Mike Kennedy, Dick Pusins"
              />
              <div className="card-body">
                <p className="card-text">Billy Turner, Mike Kennedy, Dick Pusins</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/turner_weiss.jpg')}
                alt="Billy Turner, Ken Weiss, Bernie Wilson"
              />
              <div className="card-body">
                <p className="card-text">Billy Turner, Ken Weiss, Bernie Wilson</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/turner.jpg')}
                alt="Billy Turner"
              />
              <div className="card-body">
                <p className="card-text">Billy Turner</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/turner2.jpg')}
                alt="Billy Turner praises the coaching career of Jerry Durnin"
              />
              <div className="card-body">
                <p className="card-text">Billy Turner praises the coaching career of Jerry Durnin</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/turner3.gif')}
                alt="Billy Turner"
              />
              <div className="card-body">
                <p className="card-text">Billy Turner</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/vining_gimberling_soash_sweeney_thomas_stewart.jpg')}
                alt="Jim Vining, Robert Gimerlin, Norm Soash, Ken Sweeney, Randy Thomas, Max Stewart"
              />
              <div className="card-body">
                <p className="card-text">Jim Vining, Robert Gimerlin, Norm Soash, Ken Sweeney, Randy Thomas, Max Stewart</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/vining_vining.gif')}
                alt="Frank Vining, Jim Vining"
              />
              <div className="card-body">
                <p className="card-text">Frank Vining, Jim Vining</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/weiss_balanca.jpg')}
                alt="Ken Weiss, Tony Balanca"
              />
              <div className="card-body">
                <p className="card-text">Ken Weiss, Tony Balanca</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/weiss_dimaggio.gif')}
                alt="Ken Weiss, Nick Maggio"
              />
              <div className="card-body">
                <p className="card-text">Ken Weiss, Nick Maggio</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/weiss_unknown.jpg')}
                alt="Tim Kocher, Ken Weiss"
              />
              <div className="card-body">
                <p className="card-text">Tim Kocher, Ken Weiss</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/wolf.gif')}
                alt="Don Wolfe"
              />
              <div className="card-body">
                <p className="card-text">Don Wolfe</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/woods_gonzalez.gif')}
                alt="Richard Wood, Mike Kennedy, Oscar Gonzalez"
              />
              <div className="card-body">
                <p className="card-text">Richard Wood, Mike Kennedy, Oscar Gonzalez</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-2 mx-auto">
            <div className="card">
              <img
                className="card-img-top"
                src={require('../../img/archive/zeller.jpg')}
                alt="John Zeller, Ordained Minister, eulogizes the passing of Jerry Durnin"
              />
              <div className="card-body">
                <p className="card-text">John Zeller, Ordained Minister, eulogizes the passing of Jerry Durnin</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ArchivedPhotos;
