import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import profileReducer from './profileReducer';
import postReducer from './postReducer';
import calEventReducer from './calEventReducer';
import adminReducer from './adminReducer';
import photoReducer from './photoReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  post: postReducer,
  calEvent: calEventReducer,
  admin: adminReducer,
  photo: photoReducer
});
