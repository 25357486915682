import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchVideos from './SearchVideos';
import VideoList from './VideoList';
import VideoDetail from './VideoDetail';
import youtube from '../../apis/youtube';
import { getCurrentProfile } from '../../actions/profileActions';

class YouTubeIndex extends Component {
  state = {
    term: '',
    videos: [],
    pageInfo: {},
    pageTokens: {
      nextPageToken: '',
      prevPageToken: ''
    },
    selectedVideo: null
  };

  componentDidMount() {
    this.props.getCurrentProfile();
    this.onTermSubmit('coaching sports tips');
  }

  onTermSubmit = async (term, pageToken) => {
    const res = await youtube.get('/search', {
      params: {
        q: term,
        pageToken
      }
    });

    let pageTokens = {
      nextPageToken: res.data.nextPageToken,
      prevPageToken: res.data.prevPageToken
    };

    this.setState({
      term: term,
      videos: res.data.items,
      selectedVideo: res.data.items[0],
      pageInfo: res.data.pageInfo,
      pageTokens
    });
  };

  onVideoSelect = video => {
    this.setState({ selectedVideo: video });
  };

  render() {
    const { profile } = this.props.profile;
    
    return (
      <div className="container-fluid">
        <Link to="/dashboard" className="mb-2 btn btn-light">
          Go Back
        </Link>
        <h3
          className="bg-danger rounded text-light text-center p-2 mb-2"
          style={{ width: '320px' }}
        >
          <i className="fab fa-youtube-square text-light mr-1" />
          Search Youtube
        </h3>
        <p className="lead">
          Enter search terms like Coaching Sports, Coaching Legends, or anything
          else on youtube.
        </p>
        <SearchVideos onSearchSubmit={this.onTermSubmit} />
        <div className="row">
          <div className="col-md-7">
            <VideoDetail profile={profile}  video={this.state.selectedVideo} />
          </div>
          <div className="col-md-5">
            <div className="video-list">
              <VideoList
                onVideoSelect={this.onVideoSelect}
                videos={this.state.videos}
              />
              <nav className="mt-2">
                <ul className="d-flex justify-content-center pagination pagination-lg">
                  <li
                    className={classnames('page-item', {
                      disabled: !this.state.pageTokens.prevPageToken
                    })}
                  >
                    <button
                      className="page-link"
                      onClick={() =>
                        this.onTermSubmit(
                          this.state.term,
                          this.state.pageTokens.prevPageToken
                        )
                      }
                      type="button"
                    >
                      Prev
                    </button>
                  </li>
                  <li
                    className={classnames('page-item', {
                      disabled: !this.state.pageTokens.nextPageToken
                    })}
                  >
                    {' '}
                    <button
                      className="page-link"
                      onClick={() =>
                        this.onTermSubmit(
                          this.state.term,
                          this.state.pageTokens.nextPageToken
                        )
                      }
                      type="button"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

YouTubeIndex.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(mapStateToProps, {getCurrentProfile})(YouTubeIndex);
