import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { addYoutube } from '../../actions/profileActions';

class VideoDetail extends Component {
  onSaveClick = video => {
    const youtubeData = {
      videoID: video.id.videoId,
      title: video.snippet.title,
      description: video.snippet.description,
      url: video.snippet.thumbnails.medium.url,
      width: video.snippet.thumbnails.medium.width,
      height: video.snippet.thumbnails.medium.height
  };
    this.props.addYoutube(youtubeData);
  }

  fundUserVideos = youtubevideos => {
    const {video} = this.props;

    if(youtubevideos.filter(youtubevideo => youtubevideo.videoID === video.id.videoId).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const {video, profile} = this.props;

      if (!video) {
        return (
          <div>
          <h3>Loading...</h3>
        </div>
      );
    }

    return (
      <>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title="Video Player"
            className="embed-responsive-item"
            width="auto"
            height="auto"
            src={`https://www.youtube.com/embed/${video.id.videoId}`}
            allowFullScreen
            />
        </div>
        <div className="card mt-1">
          <div className="card-body">
            <h5 className="card-title">{video.snippet.title}</h5>
            <p className="card-text">{video.snippet.description}</p>
            <p className="card-text">
              Uploaded or Hosted by - {video.snippet.channelTitle}
            </p>
            <Moment format="MM/YYYY">{video.snippet.publishedAt}</Moment>
            <br />

            {!this.fundUserVideos(profile.youtubevideos) ? (
              <button
              onClick={() => this.onSaveClick(video)}
              type="button"
              className="btn btn-primary"
            >
            Save Video
            </button>
            ) : null}
            
          </div>
        </div>
      </>
    );
  }
}

VideoDetail.propTypes = {
  addYoutube: PropTypes.func.isRequired,
};

export default connect(null, {addYoutube})(VideoDetail);
