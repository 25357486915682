import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { deleteComment } from '../../actions/postActions';

class CommentItem extends Component {
  onDeleteClick = (postId, commentId) => {
    this.props.deleteComment(postId, commentId);
  }

  render() {
    const { comment, postId, auth } = this.props;

    const defaultProfileImg = (
      <img
        className="img-fluid rounded-circle"
        src={require('../../img/default_profile.png')}
        alt={comment.name}
        title={comment.name}
      />
    );

    const uploadedProfileImg = (
      <img
        className="img-fluid rounded-circle"
        src={comment.avatar}
        alt={comment.name}
        title={comment.name}
      />
    );

    return (
      <div className="card card-body mb-3">
        <div className="row">
          <div className="col-md-2">
            <Link to={`/profile/user/${comment.user}`}>
              {comment.avatar ? uploadedProfileImg : defaultProfileImg}
            </Link>
            <br />
            <p className="text-center">{comment.name}</p>
          </div>
          <div className="col-md-10">
            <p className="lead">{comment.body}</p>
            <p className="badge badge-light p-2">
              When:{' '}
              <Moment element="span" className="text-info" fromNow>
                {comment.date}
              </Moment>
            </p>
            <br />
            {comment.user === auth.user.id ? (
              <button
                type="button"
                onClick={() => this.onDeleteClick(postId, comment._id)}
                className="btn btn-danger mr-1"
              >
                <i className="fas fa-times" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

CommentItem.propTypes = {
  deleteComment: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired,
  postId: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { deleteComment }
)(CommentItem);
