import React from 'react';

const VideoItem = ({ video, onVideoSelect }) => {
  const videoThumbnail = {
    url: video.snippet.thumbnails.medium.url,
    width: video.snippet.thumbnails.medium.width,
    height: video.snippet.thumbnails.medium.height
  };

  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => onVideoSelect(video)}
      className="card"
    >
      <div className="card-body">
        <div className="row">
          <div className="col-lg-5">
            <img
              src={videoThumbnail.url}
              alt={videoThumbnail.url}
              style={{
                width: '12rem',
                height: '8rem'
              }}
              className="img-thumbnail"
            />
          </div>
          <div className="col-lg-7">
            <h5 className="card-title">{video.snippet.title}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
