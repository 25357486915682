import axios from 'axios';

import {
  ADD_CALEVENT,
  GET_CALEVENT,
  GET_CALEVENTS,
  CALEVENT_LOADING,
  DELETE_CALEVENT,
  GET_ERRORS,
  CLEAR_ERRORS 
} from './types';

// Add Calendar Event 
export const addCalEvent = (calEventData) => dispatch => {
  dispatch(clearErrors());
  axios.post('/api/cal-events', calEventData)
  .then(res =>
    dispatch({
      type: ADD_CALEVENT,
      payload: res.data
    })
  )
  .catch(err =>
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  );
};


// Get all Calendar Events
export const getCalEvents = () => dispatch => {
  dispatch(setCalEventLoading());
  axios
    .get('/api/cal-events')
    .then(res =>
      dispatch({
        type: GET_CALEVENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CALEVENTS,
        payload: null
      })
    );
};

// Get Single Calendar Event
export const getcalEvent = id => dispatch => {
  dispatch(setCalEventLoading());
  axios
    .get(`/api/cal-events/${id}`)
    .then(res =>
      dispatch({
        type: GET_CALEVENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CALEVENT,
        payload: null
      })
    );
};

// Delete Calendar Event
export const deleteCalEvent = id => dispatch => {
  axios
    .delete(`/api/cal-events/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_CALEVENT,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Set Loading state
export const setCalEventLoading = () => {
  return {
    type: CALEVENT_LOADING
  };
};

// Clear Errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
