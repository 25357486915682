import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetPass } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';

class ResetPass extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      errors: {}
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/');
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.errors !== nextProps.errors) {
      return { errors: nextProps.errors };
    }
    return null;
  }

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
    };

    this.props.resetPass(userData, this.props.history);
    this.setState({ email: '' });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="reset-pass">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="text-center">Reset Password</h1>
              <p className="lead text-center">
                Request a new password. <small>An Email will be sent to you</small>
              </p>
              <form onSubmit={this.onSubmit} noValidate>
                <TextFieldGroup
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />
                <input
                  value="Submit"
                  type="submit"
                  className="btn btn-primary btn-block mt-4"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPass.propTypes = {
  resetPass: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { resetPass }
)(withRouter(ResetPass));
