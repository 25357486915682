import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class QuoteSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 9000,
      slide: 'blockquote',
      arrows: false
    };
    const quotes = [
      {
        name: 'Muhammad Ali',
        body:
          'A man who views the world the same at fifty as he does at twenty has wasted thirty years of his life.'
      },
      {
        name: 'Harry Toscano',
        body:
          "I'm hitting the woods just great, but I'm having a terrible time getting out of them."
      },
      {
        name: 'Lee Trevino',
        body: 'A hungry dog hunts best.'
      },
      {
        name: 'Chuck Noll',
        body:
          'A life of frustration is inevitable for any coach whose main enjoyment is winning.'
      },
      {
        name: 'Babe Ruth',
        body: 'Every strike brings me closer to the next home run.'
      },
      {
        name: 'Yogi Berra',
        body:
          "You've go to be very careful if you don't know where you're going, because you might not get there."
      },
      {
        name: 'Terry Bradshaw',
        body: "I may be dumb, but I'm not stupid."
      },
      {
        name: 'Muhammad Ali',
        body:
          "Float like a butterfly, sting like a bee. Your hands can't hit what you're eyes can't see."
      },
      {
        name: 'Bobby Knight',
        body:
          'The key is not the "will to win" - everybody has that. It is the will to prepare to win that is important.'
      },
      {
        name: 'Vince Lombardi',
        body: "If winning isn't everything, why do we keep score?"
      },
      {
        name: 'Dr. Seuss',
        body: "Don't cry because it's over. Smile because it happened."
      },
      {
        name: 'Sam Snead',
        body:
          'These greens are so fast I have to hold my putter over the ball and hit it with the shadow.'
      },
      {
        name: 'Yogi Berra',
        body: "It's tough to make predictions, especially about the future."
      },
      {
        name: 'Phil Rizzuto',
        body:
          "I'm glad I don't play anymore. I could never learn all those handshakes."
      },
      {
        name: 'Wilt Chamberlain',
        body:
          "They say that nobody is perfect. Then they tell you practice makes perfect. I wish they'd make up their minds."
      },
      {
        name: 'Lou Deva',
        body:
          "He's a guy who get up at six o'clock in the morning regardless of what time it is."
      },
      {
        name: 'Chi Chi Rodriguez',
        body: "I don't exagerate, I remember big."
      },
      {
        name: 'Vince Lombardi',
        body: "We didn't lose the game, we just ran out of time."
      },
      {
        name: 'John McKay',
        body:
          " We didn't tackle well today, but we made up for it by not blocking."
      }
    ];
    const quote = quotes.map(quote => {
      return (
        <blockquote key={quote.name} className="blockquote text-right">
          <p className="mb-0 p-2">{quote.body}</p>
          <footer className="blockquote-footer text-success">
            {quote.name}
          </footer>
        </blockquote>
      );
    });
    return (
      <div className="quotes">
        <Slider {...settings}>{quote}</Slider>
      </div>
    );
  }
}

export default QuoteSlider;
