import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProfileYoutube extends Component {
  render() {
    const {profile} = this.props;

    // Get first name
    const firstName = profile.user.name.trim().split(' ')[0];

    const ytVideos = profile.youtubevideos.map(ytVideo => (
      <div className="p-2" key={ytVideo._id}>
        <div>
          <button type="button" data-toggle="modal" data-target={`#youTubeModal${ytVideo._id}`}>
            <img
              src={ytVideo.url}
              alt={ytVideo.url}
              style={{
                width: '375px',
                height: '250px'
              }}
              className="img-thumbnail"
            />
          </button>
          <p className="text-info">{ytVideo.title}</p>
          <p>{ytVideo.description}</p>
        </div>
      
      <div className="modal fade" id={`youTubeModal${ytVideo._id}`} tabIndex="-1" role="dialog" aria-labelledby={`youTubeModal${ytVideo._id}Title`} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    title="Video Player"
                    className="embed-responsive-item"
                    width="auto"
                    height="auto"
                    src={`https://www.youtube.com/embed/${ytVideo.videoID}`}
                    allowFullScreen
                    />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      <hr />
      </div>
    ));

    return (
      <div className="card">
        <div className="card-body">
          <h3 className="text-center text-info">{firstName}'s Shared YouTube Videos</h3>
          {ytVideos.length > 0 ? (
            <span>{ytVideos}</span>
          ) : (
            <p className="text-center">No Videos Saved</p>
          )}
        </div>
      </div>
    );
  }
}

ProfileYoutube.propTypes = {
  profile: PropTypes.object.isRequired
};

export default ProfileYoutube;
