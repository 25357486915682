import React, { Component } from 'react';

class SearchVideos extends Component {
  state = {
    term: ''
  };

  onChange = e => {
    this.setState({ term: e.target.value });
  };

  onSearchSubmit = e => {
    e.preventDefault();

    this.props.onSearchSubmit(this.state.term);
  };

  render() {
    return (
      <div>
        
        <form onSubmit={this.onSearchSubmit}>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Search Youtube..."
              name="term"
              value={this.state.term}
              onChange={this.onChange}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default SearchVideos;
