import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUser, updateUser } from '../../actions/adminActions';
import SelectListGroup from '../common/SelectListGroup';
import isEmpty from '../../validation/is-empty';

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: '',
      errors: {}
    };
  }
  componentDidMount() {
    this.props.getUser(this.props.match.params.id);

    const {user} = this.props.admin;

    user.name = !isEmpty(user.name) ? user.name : '';
    user.email = !isEmpty(user.email) ? user.email : '';
    user.role = !isEmpty(user.role) ? user.role : '';

    this.setState({
      role: user.role
    });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.errors !== nextProps.errors) {
      return { errors: nextProps.errors };
    }
    return null;
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitHandler = e => {
    e.preventDefault();

    const  userId  = this.props.admin.user._id;
    const userData = {
      role: this.state.role,
    };
    
    this.props.updateUser(userId, userData);
  };
  
  render() {
    const { errors } = this.state;
    const { user, loading } = this.props.admin;
    
    const options = [
      { label: '* Select new role', value: 0 },
      { label: 'User', value: 'User' },
      { label: 'Manager', value: 'Manager' },
      { label: 'Admin', value: 'Admin' },
      { label: 'Guest', value: 'Guest' }
    ];

    return (
      <div className="admin-dashboard">
        <Link to="/admin" className="btn btn-light">
          Go Back
        </Link>
        <h1 className="display-4 text-center">
          Admin Dashboard
        </h1>
        <ul className="list-group list-group-flush mb-3">
          <li className="list-group-item">
            The <span className="text-info">User</span> group is for all signed up coaches with normal access rights to their own info.
          </li>
          <li className="list-group-item">
            The <span className="text-info">Manager</span> group will give the rights to preform certian task within the application, like update recurring meet-up event, add special events, send email notices for events, along with updating the applications photo gallary. <br /><span className="text-danger">* Note</span> - You can have many manager roles, at this time, all mangers will have the same access and rights.
          </li>
          <li className="list-group-item">
            The <span className="text-info">Admin</span> group will have access to all of the above, plus change other roles to Manager, User, Admin, or Guest. <br /><span className="text-danger">* Note</span> - There must be at least one admin user.
          </li>
          <li className="list-group-item">
            The <span className="text-info">Guest</span> group will have no access to most of the application. If a user is using the application inappropriatlly. <br /><span className="text-danger">* Note</span> - User can still see public pages only.
          </li>
          <li className="list-group-item">
            Only users can remove their own posts, but Admin users may remove a user all together if no longer apart of <span className="text-info">'Coaching Legends'</span> social club.
          </li>
        </ul>
        <div className="card">
          <div className="card-header text-center">
            Role for <span className="text-info">{user.name}</span> currently is <span className="text-info">{user.role}</span>
          </div>
          <div className="card-body min-h-100">
            <form onSubmit={this.onSubmitHandler}>
              <div className="form-row">
                <div className="col">
                  <span>{user.name}</span>
                </div>
                <div className="col">
                  <span>{user.email}</span>
                </div>
                <div className="col">
                  <SelectListGroup
                    name="role"
                    value={this.state.role}
                    onChange={this.onChange}
                    options={options}
                    info="Update User's Role"
                    error={errors.role}
                  />
                </div>
                <div className="col">
                  <button className="btn btn-outline-success" type="submit">
                    Updat{loading ? 'ing' : "e"} User Role
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

UserDetails.propTypes = {
  getUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  admin: state.admin,
  errors: state.errors
});

export default connect(mapStateToProps, {getUser, updateUser})(UserDetails);
